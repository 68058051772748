/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ViderefoerelseSammenligningRaekke } from './viderefoerelseSammenligningRaekke';
import { ViderefoerelseSammenligningRaekkeSchema } from './viderefoerelseSammenligningRaekke';
import * as JoiObj from "joi";


    export const ViderefoerelseSammenligningSchema = JoiObj.object({
        diagnose:
                JoiObj.array().items(
        
        JoiObj.link('#ViderefoerelseSammenligningRaekkeSchema')

        )
        

.required()


        ,
        earlycare:
                JoiObj.array().items(
        
        JoiObj.link('#ViderefoerelseSammenligningRaekkeSchema')

        )
        

.required()


        ,
        efterAendringOrdningDatoStreng:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        forebygger:
                JoiObj.array().items(
        
        JoiObj.link('#ViderefoerelseSammenligningRaekkeSchema')

        )
        

.required()


        ,
        hs:
                JoiObj.array().items(
        
        JoiObj.link('#ViderefoerelseSammenligningRaekkeSchema')

        )
        

.required()


        ,
        hsBoerne:
                JoiObj.array().items(
        
        JoiObj.link('#ViderefoerelseSammenligningRaekkeSchema')

        )
        

.required()


        ,
        indbetalingssikring:
                JoiObj.array().items(
        
        JoiObj.link('#ViderefoerelseSammenligningRaekkeSchema')

        )
        

.required()


        ,
        indbetalingssikringNedsat2_3:
                JoiObj.array().items(
        
        JoiObj.link('#ViderefoerelseSammenligningRaekkeSchema')

        )
        

.required()


        ,
        indbetalingssikringNedsatHalv:
                JoiObj.array().items(
        
        JoiObj.link('#ViderefoerelseSammenligningRaekkeSchema')

        )
        

.required()


        ,
        indbetalingssikringNedsatHalv_Hel:
                JoiObj.array().items(
        
        JoiObj.link('#ViderefoerelseSammenligningRaekkeSchema')

        )
        

.required()


        ,
        ks:
                JoiObj.array().items(
        
        JoiObj.link('#ViderefoerelseSammenligningRaekkeSchema')

        )
        

.required()


        ,
        ksBoerne:
                JoiObj.array().items(
        
        JoiObj.link('#ViderefoerelseSammenligningRaekkeSchema')

        )
        

.required()


        ,
        liv:
                JoiObj.array().items(
        
        JoiObj.link('#ViderefoerelseSammenligningRaekkeSchema')

        )
        

.required()


        ,
        livBoernePension:
                JoiObj.array().items(
        
        JoiObj.link('#ViderefoerelseSammenligningRaekkeSchema')

        )
        

.required()


        ,
        livDepotsikring:
                JoiObj.array().items(
        
        JoiObj.link('#ViderefoerelseSammenligningRaekkeSchema')

        )
        

.required()


        ,
        livEngang:
                JoiObj.array().items(
        
        JoiObj.link('#ViderefoerelseSammenligningRaekkeSchema')

        )
        

.required()


        ,
        nuvaerendeOrdningDatoStreng:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        resourceforloeb:
                JoiObj.array().items(
        
        JoiObj.link('#ViderefoerelseSammenligningRaekkeSchema')

        )
        

.required()


        ,
        risikoForhoejet:
            
                    JoiObj.boolean()
                


.required()


        ,
        sundhedslinje:
                JoiObj.array().items(
        
        JoiObj.link('#ViderefoerelseSammenligningRaekkeSchema')

        )
        

.required()


        ,
        tae:
                JoiObj.array().items(
        
        JoiObj.link('#ViderefoerelseSammenligningRaekkeSchema')

        )
        

.required()


        ,
        taeBoerne:
                JoiObj.array().items(
        
        JoiObj.link('#ViderefoerelseSammenligningRaekkeSchema')

        )
        

.required()


        ,
        taeEngang:
                JoiObj.array().items(
        
        JoiObj.link('#ViderefoerelseSammenligningRaekkeSchema')

        )
        

.required()


        ,
        existingPolicyOverdue:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ViderefoerelseSammenligningRaekkeSchema)
                .shared(ViderefoerelseSammenligningRaekkeSchema)
                .shared(ViderefoerelseSammenligningRaekkeSchema)
                .shared(ViderefoerelseSammenligningRaekkeSchema)
                .shared(ViderefoerelseSammenligningRaekkeSchema)
                .shared(ViderefoerelseSammenligningRaekkeSchema)
                .shared(ViderefoerelseSammenligningRaekkeSchema)
                .shared(ViderefoerelseSammenligningRaekkeSchema)
                .shared(ViderefoerelseSammenligningRaekkeSchema)
                .shared(ViderefoerelseSammenligningRaekkeSchema)
                .shared(ViderefoerelseSammenligningRaekkeSchema)
                .shared(ViderefoerelseSammenligningRaekkeSchema)
                .shared(ViderefoerelseSammenligningRaekkeSchema)
                .shared(ViderefoerelseSammenligningRaekkeSchema)
                .shared(ViderefoerelseSammenligningRaekkeSchema)
                .shared(ViderefoerelseSammenligningRaekkeSchema)
                .shared(ViderefoerelseSammenligningRaekkeSchema)
                .shared(ViderefoerelseSammenligningRaekkeSchema)
                .shared(ViderefoerelseSammenligningRaekkeSchema)
                .shared(ViderefoerelseSammenligningRaekkeSchema)
.id('ViderefoerelseSammenligningSchema')

export interface ViderefoerelseSammenligning { 
    diagnose: Array<ViderefoerelseSammenligningRaekke>;
    earlycare: Array<ViderefoerelseSammenligningRaekke>;
    efterAendringOrdningDatoStreng: string;
    forebygger: Array<ViderefoerelseSammenligningRaekke>;
    hs: Array<ViderefoerelseSammenligningRaekke>;
    hsBoerne: Array<ViderefoerelseSammenligningRaekke>;
    indbetalingssikring: Array<ViderefoerelseSammenligningRaekke>;
    indbetalingssikringNedsat2_3: Array<ViderefoerelseSammenligningRaekke>;
    indbetalingssikringNedsatHalv: Array<ViderefoerelseSammenligningRaekke>;
    indbetalingssikringNedsatHalv_Hel: Array<ViderefoerelseSammenligningRaekke>;
    ks: Array<ViderefoerelseSammenligningRaekke>;
    ksBoerne: Array<ViderefoerelseSammenligningRaekke>;
    liv: Array<ViderefoerelseSammenligningRaekke>;
    livBoernePension: Array<ViderefoerelseSammenligningRaekke>;
    livDepotsikring: Array<ViderefoerelseSammenligningRaekke>;
    livEngang: Array<ViderefoerelseSammenligningRaekke>;
    nuvaerendeOrdningDatoStreng: string;
    resourceforloeb: Array<ViderefoerelseSammenligningRaekke>;
    risikoForhoejet: boolean;
    sundhedslinje: Array<ViderefoerelseSammenligningRaekke>;
    tae: Array<ViderefoerelseSammenligningRaekke>;
    taeBoerne: Array<ViderefoerelseSammenligningRaekke>;
    taeEngang: Array<ViderefoerelseSammenligningRaekke>;
    existingPolicyOverdue: boolean;
}

