import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        AarsagTilBrug,
        AarsagTilBrugSchema,
        CustomerActivity,
        CustomerActivitySchema,
        MitID,
        MitIDSchema,
        NavnOgStatusData,
        NavnOgStatusDataSchema,
        SuccessResponse,
        SuccessResponseSchema
} from '../model/models';

import { BrugerService } from './bruger.service';


@Injectable({
    providedIn: 'root'
})
export class BrugerStore extends GenericStore {

            private brugerIndexCache: Map<string, RestCacheEntry<NavnOgStatusData>> = new Map<string, RestCacheEntry<NavnOgStatusData>>();
            private brugermitpfaaccessmaxreturnedShowCache: Map<string, RestCacheEntry<Array<CustomerActivity>>> = new Map<string, RestCacheEntry<Array<CustomerActivity>>>();
            private brugerlogoutIndexCache: Map<string, RestCacheEntry<string>> = new Map<string, RestCacheEntry<string>>();
            private brugermitidIndexCache: Map<string, RestCacheEntry<MitID>> = new Map<string, RestCacheEntry<MitID>>();

    constructor(private brugerService: BrugerService) {
        super();
    }

    invalidateAll(): void {
                
                
                
                this.invalidatebrugerIndex();
                
                this.invalidatebrugerlogoutIndex();
                
                this.invalidatebrugermitidIndex();
                
                
                this.invalidatebrugermitpfaaccessmaxreturnedShow();
                
    }




                public brugerAarsagtilbrugPost(body?: string) : Observable<SuccessResponse> {
                    return this.brugerService.brugerAarsagtilbrugPost(body).pipe(
                        map(value => {
                            
                            
                                    this.throwOrHandleValidationError(SuccessResponseSchema, value);
                            return value;
                        })
                    );
                }




                public brugerGet() : Observable<NavnOgStatusData> {
                    let hash = "0";
                    if (this.brugerIndexCache.has(hash)) {
                        return this.brugerIndexCache.get(hash)!.observable;
                    } else {
                        let brugerIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let brugerIndex = this.initiateStream(brugerIndexSubject, this.brugerService.brugerGet(), NavnOgStatusDataSchema);
                        this.brugerIndexCache.set(hash, new RestCacheEntry<NavnOgStatusData>(brugerIndex, brugerIndexSubject));
                        return brugerIndex;
                    }
                }

                public invalidatebrugerIndex() {
                    this.brugerIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






                public brugerLogoutGet() : Observable<string> {
                    let hash = "0";
                    if (this.brugerlogoutIndexCache.has(hash)) {
                        return this.brugerlogoutIndexCache.get(hash)!.observable;
                    } else {
                        let brugerlogoutIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let brugerlogoutIndex = this.initiateStream(brugerlogoutIndexSubject, this.brugerService.brugerLogoutGet(), JoiObj.any());
                        this.brugerlogoutIndexCache.set(hash, new RestCacheEntry<string>(brugerlogoutIndex, brugerlogoutIndexSubject));
                        return brugerlogoutIndex;
                    }
                }

                public invalidatebrugerlogoutIndex() {
                    this.brugerlogoutIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






                public brugerMitidGet() : Observable<MitID> {
                    let hash = "0";
                    if (this.brugermitidIndexCache.has(hash)) {
                        return this.brugermitidIndexCache.get(hash)!.observable;
                    } else {
                        let brugermitidIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let brugermitidIndex = this.initiateStream(brugermitidIndexSubject, this.brugerService.brugerMitidGet(), MitIDSchema);
                        this.brugermitidIndexCache.set(hash, new RestCacheEntry<MitID>(brugermitidIndex, brugermitidIndexSubject));
                        return brugermitidIndex;
                    }
                }

                public invalidatebrugermitidIndex() {
                    this.brugermitidIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }









                public brugerMitidPut(mitID?: MitID) : Observable<any> {
                    return this.brugerService.brugerMitidPut(mitID).pipe(
                        map(value => {
                            
                            this.invalidatebrugermitidIndex();
                            return value;
                        })
                    );
                }


                public brugerMitpfaAccessMaxreturnedGet(maxreturned: string) : Observable<Array<CustomerActivity>> {
                    let hash = this.hashKey((maxreturned.toString() || "0"));
                    if (this.brugermitpfaaccessmaxreturnedShowCache.has(hash)) {
                        return this.brugermitpfaaccessmaxreturnedShowCache.get(hash)!.observable;
                    } else {
                        let brugermitpfaaccessmaxreturnedShowSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let brugermitpfaaccessmaxreturnedShow = this.initiateStream(brugermitpfaaccessmaxreturnedShowSubject, this.brugerService.brugerMitpfaAccessMaxreturnedGet(maxreturned), CustomerActivitySchema);
                        this.brugermitpfaaccessmaxreturnedShowCache.set(hash, new RestCacheEntry<Array<CustomerActivity>>(brugermitpfaaccessmaxreturnedShow, brugermitpfaaccessmaxreturnedShowSubject));
                        return brugermitpfaaccessmaxreturnedShow;
                    }
                }

                public invalidatebrugermitpfaaccessmaxreturnedShowById(maxreturned: string) {
                    let hash = this.hashKey((maxreturned.toString() || "0"));
                    if (this.brugermitpfaaccessmaxreturnedShowCache.has(hash)) {
                        this.brugermitpfaaccessmaxreturnedShowCache.get(hash)!.subject.next(0);
                    }
                }

                public invalidatebrugermitpfaaccessmaxreturnedShow() {
                    this.brugermitpfaaccessmaxreturnedShowCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }







}