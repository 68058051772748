export * from './ApiService';
import { ApiService } from './ApiService';
export * from './beneficiary.service';
export * from './beneficiary.serviceStore';
import { BeneficiaryService } from './beneficiary.service';
import { BeneficiaryStore } from './beneficiary.serviceStore';
export * from './bruger.service';
export * from './bruger.serviceStore';
import { BrugerService } from './bruger.service';
import { BrugerStore } from './bruger.serviceStore';
export * from './brugerundersoegelse.service';
export * from './brugerundersoegelse.serviceStore';
import { BrugerundersoegelseService } from './brugerundersoegelse.service';
import { BrugerundersoegelseStore } from './brugerundersoegelse.serviceStore';
export * from './climatefootprint.service';
export * from './climatefootprint.serviceStore';
import { ClimatefootprintService } from './climatefootprint.service';
import { ClimatefootprintStore } from './climatefootprint.serviceStore';
export * from './customerdigitalidentity.service';
export * from './customerdigitalidentity.serviceStore';
import { CustomerdigitalidentityService } from './customerdigitalidentity.service';
import { CustomerdigitalidentityStore } from './customerdigitalidentity.serviceStore';
export * from './customerprofile.service';
export * from './customerprofile.serviceStore';
import { CustomerprofileService } from './customerprofile.service';
import { CustomerprofileStore } from './customerprofile.serviceStore';
export * from './digitalsalg.service';
export * from './digitalsalg.serviceStore';
import { DigitalsalgService } from './digitalsalg.service';
import { DigitalsalgStore } from './digitalsalg.serviceStore';
export * from './dynamicadvising.service';
export * from './dynamicadvising.serviceStore';
import { DynamicadvisingService } from './dynamicadvising.service';
import { DynamicadvisingStore } from './dynamicadvising.serviceStore';
export * from './externaltransfer.service';
export * from './externaltransfer.serviceStore';
import { ExternaltransferService } from './externaltransfer.service';
import { ExternaltransferStore } from './externaltransfer.serviceStore';
export * from './fordele.service';
export * from './fordele.serviceStore';
import { FordeleService } from './fordele.service';
import { FordeleStore } from './fordele.serviceStore';
export * from './forsikring.service';
export * from './forsikring.serviceStore';
import { ForsikringService } from './forsikring.service';
import { ForsikringStore } from './forsikring.serviceStore';
export * from './forsikringsguide.service';
export * from './forsikringsguide.serviceStore';
import { ForsikringsguideService } from './forsikringsguide.service';
import { ForsikringsguideStore } from './forsikringsguide.serviceStore';
export * from './forsikringsoversigt.service';
export * from './forsikringsoversigt.serviceStore';
import { ForsikringsoversigtService } from './forsikringsoversigt.service';
import { ForsikringsoversigtStore } from './forsikringsoversigt.serviceStore';
export * from './indbetaling.service';
export * from './indbetaling.serviceStore';
import { IndbetalingService } from './indbetaling.service';
import { IndbetalingStore } from './indbetaling.serviceStore';
export * from './indbetalingsaftaler.service';
export * from './indbetalingsaftaler.serviceStore';
import { IndbetalingsaftalerService } from './indbetalingsaftaler.service';
import { IndbetalingsaftalerStore } from './indbetalingsaftaler.serviceStore';
export * from './individualcustomerdossier.service';
export * from './individualcustomerdossier.serviceStore';
import { IndividualcustomerdossierService } from './individualcustomerdossier.service';
import { IndividualcustomerdossierStore } from './individualcustomerdossier.serviceStore';
export * from './individualcustomerprofile.service';
export * from './individualcustomerprofile.serviceStore';
import { IndividualcustomerprofileService } from './individualcustomerprofile.service';
import { IndividualcustomerprofileStore } from './individualcustomerprofile.serviceStore';
export * from './investering.service';
export * from './investering.serviceStore';
import { InvesteringService } from './investering.service';
import { InvesteringStore } from './investering.serviceStore';
export * from './kundekapital.service';
export * from './kundekapital.serviceStore';
import { KundekapitalService } from './kundekapital.service';
import { KundekapitalStore } from './kundekapital.serviceStore';
export * from './logout.service';
export * from './logout.serviceStore';
import { LogoutService } from './logout.service';
import { LogoutStore } from './logout.serviceStore';
export * from './microappconfiguration.service';
export * from './microappconfiguration.serviceStore';
import { MicroappconfigurationService } from './microappconfiguration.service';
import { MicroappconfigurationStore } from './microappconfiguration.serviceStore';
export * from './mineaendringer.service';
export * from './mineaendringer.serviceStore';
import { MineaendringerService } from './mineaendringer.service';
import { MineaendringerStore } from './mineaendringer.serviceStore';
export * from './mobilesigning.service';
export * from './mobilesigning.serviceStore';
import { MobilesigningService } from './mobilesigning.service';
import { MobilesigningStore } from './mobilesigning.serviceStore';
export * from './onboarding.service';
export * from './onboarding.serviceStore';
import { OnboardingService } from './onboarding.service';
import { OnboardingStore } from './onboarding.serviceStore';
export * from './onlineaccount.service';
export * from './onlineaccount.serviceStore';
import { OnlineaccountService } from './onlineaccount.service';
import { OnlineaccountStore } from './onlineaccount.serviceStore';
export * from './openforbusiness.service';
export * from './openforbusiness.serviceStore';
import { OpenforbusinessService } from './openforbusiness.service';
import { OpenforbusinessStore } from './openforbusiness.serviceStore';
export * from './overfoersel.service';
export * from './overfoersel.serviceStore';
import { OverfoerselService } from './overfoersel.service';
import { OverfoerselStore } from './overfoersel.serviceStore';
export * from './pensioninfo.service';
export * from './pensioninfo.serviceStore';
import { PensioninfoService } from './pensioninfo.service';
import { PensioninfoStore } from './pensioninfo.serviceStore';
export * from './pensionskunde.service';
export * from './pensionskunde.serviceStore';
import { PensionskundeService } from './pensionskunde.service';
import { PensionskundeStore } from './pensionskunde.serviceStore';
export * from './pensionsplan.service';
export * from './pensionsplan.serviceStore';
import { PensionsplanService } from './pensionsplan.service';
import { PensionsplanStore } from './pensionsplan.serviceStore';
export * from './pensionsrapport.service';
export * from './pensionsrapport.serviceStore';
import { PensionsrapportService } from './pensionsrapport.service';
import { PensionsrapportStore } from './pensionsrapport.serviceStore';
export * from './prisliste.service';
export * from './prisliste.serviceStore';
import { PrislisteService } from './prisliste.service';
import { PrislisteStore } from './prisliste.serviceStore';
export * from './savings.service';
export * from './savings.serviceStore';
import { SavingsService } from './savings.service';
import { SavingsStore } from './savings.serviceStore';
export * from './savingsPlan.service';
export * from './savingsPlan.serviceStore';
import { SavingsPlanService } from './savingsPlan.service';
import { SavingsPlanStore } from './savingsPlan.serviceStore';
export * from './savingsreturn.service';
export * from './savingsreturn.serviceStore';
import { SavingsreturnService } from './savingsreturn.service';
import { SavingsreturnStore } from './savingsreturn.serviceStore';
export * from './skadesanmeldelse.service';
export * from './skadesanmeldelse.serviceStore';
import { SkadesanmeldelseService } from './skadesanmeldelse.service';
import { SkadesanmeldelseStore } from './skadesanmeldelse.serviceStore';
export * from './spoergeskema.service';
export * from './spoergeskema.serviceStore';
import { SpoergeskemaService } from './spoergeskema.service';
import { SpoergeskemaStore } from './spoergeskema.serviceStore';
export * from './systemadministration.service';
export * from './systemadministration.serviceStore';
import { SystemadministrationService } from './systemadministration.service';
import { SystemadministrationStore } from './systemadministration.serviceStore';
export * from './udbetalingsplan.service';
export * from './udbetalingsplan.serviceStore';
import { UdbetalingsplanService } from './udbetalingsplan.service';
import { UdbetalingsplanStore } from './udbetalingsplan.serviceStore';
export * from './uploadskadesanmeldelsedocument.service';
export * from './uploadskadesanmeldelsedocument.serviceStore';
import { UploadskadesanmeldelsedocumentService } from './uploadskadesanmeldelsedocument.service';
import { UploadskadesanmeldelsedocumentStore } from './uploadskadesanmeldelsedocument.serviceStore';
export const APIS = [
    ApiService,
            BeneficiaryService, BeneficiaryStore
        , 
                BrugerService, BrugerStore
        , 
                BrugerundersoegelseService, BrugerundersoegelseStore
        , 
                ClimatefootprintService, ClimatefootprintStore
        , 
                CustomerdigitalidentityService, CustomerdigitalidentityStore
        , 
                CustomerprofileService, CustomerprofileStore
        , 
                DigitalsalgService, DigitalsalgStore
        , 
                DynamicadvisingService, DynamicadvisingStore
        , 
                ExternaltransferService, ExternaltransferStore
        , 
                FordeleService, FordeleStore
        , 
                ForsikringService, ForsikringStore
        , 
                ForsikringsguideService, ForsikringsguideStore
        , 
                ForsikringsoversigtService, ForsikringsoversigtStore
        , 
                IndbetalingService, IndbetalingStore
        , 
                IndbetalingsaftalerService, IndbetalingsaftalerStore
        , 
                IndividualcustomerdossierService, IndividualcustomerdossierStore
        , 
                IndividualcustomerprofileService, IndividualcustomerprofileStore
        , 
                InvesteringService, InvesteringStore
        , 
                KundekapitalService, KundekapitalStore
        , 
                LogoutService, LogoutStore
        , 
                MicroappconfigurationService, MicroappconfigurationStore
        , 
                MineaendringerService, MineaendringerStore
        , 
                MobilesigningService, MobilesigningStore
        , 
                OnboardingService, OnboardingStore
        , 
                OnlineaccountService, OnlineaccountStore
        , 
                OpenforbusinessService, OpenforbusinessStore
        , 
                OverfoerselService, OverfoerselStore
        , 
                PensioninfoService, PensioninfoStore
        , 
                PensionskundeService, PensionskundeStore
        , 
                PensionsplanService, PensionsplanStore
        , 
                PensionsrapportService, PensionsrapportStore
        , 
                PrislisteService, PrislisteStore
        , 
                SavingsService, SavingsStore
        , 
                SavingsPlanService, SavingsPlanStore
        , 
                SavingsreturnService, SavingsreturnStore
        , 
                SkadesanmeldelseService, SkadesanmeldelseStore
        , 
                SpoergeskemaService, SpoergeskemaStore
        , 
                SystemadministrationService, SystemadministrationStore
        , 
                UdbetalingsplanService, UdbetalingsplanStore
        , 
                UploadskadesanmeldelsedocumentService, UploadskadesanmeldelsedocumentStore
        
    ];
// do not change the line below manually, use UpdateVersion.cmd instead
export const DS_API_VERSION = "91.0.5"; 
