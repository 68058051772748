export enum InjuryClaimType {
  LOSS_OF_OCCUPATIONAL_CAPACITY = 'tae',
  CRITICAL_ILLNESS = 'ks',
  HEALTH_INSURANCE = 'hs'
}

export enum CoverageType {
  HEALTH_WORK = 333,
  HEALTH_SPARETIME = 334,
  HEALTH_CHILDREN = 335
}
