<co-disable-overlay *ngIf="overlay.show">
  <ng-container
    *ngIf="
      !overlay.policyBlocked &&
      !overlay.payoutInProgress &&
      (overlay.insuranceGuideTooOld || overlay.locScale)
    "
  >
    <h2 [coContent]="'DL.TF01.C140'"></h2>
    <p [coContent]="'DL.TF01.C141'" *ngIf="overlay.insuranceGuideTooOld"></p>
    <p [coContent]="'DL.TF01.C175'" *ngIf="!overlay.insuranceGuideTooOld"></p>
    <p [coContent]="'DL.TF01.C142'"></p>
    <button
      mat-raised-button
      [coContent]="'DL.TF01.C44'"
      (click)="gotoInsuranceGuide()"
    >
      Gå til forsikringsuiden
    </button>
  </ng-container>

  <ng-container *ngIf="overlay.policyBlocked || overlay.payoutInProgress">
    <h2 [coContent]="'DL.TF01.C136'"></h2>
    <p [coContent]="'DL.TF01.C137'"></p>
    <button
      mat-raised-button
      [coContent]="'DL.TF01.C64'"
      (click)="gotoOverview()"
    >
      Gå til forsikringsoverblik
    </button>
  </ng-container>
</co-disable-overlay>

<div class="adjust-insurance">
  <co-introbox
    [title]="'DL.TF01.C19'"
    [description]="'DL.TF01.C20'"
    *ngIf="!pageStates.showHealthInformation && !pageStates.receipt"
  >
    <p [coContent]="'DL.TF01.C21'"></p>
    <div class="text--bold" [coContent]="'DL.TF01.C22'"></div>
    <ul class="bullet-list">
      <li [coContent]="'DL.TF01.C47'"></li>
      <li [coContent]="'DL.TF01.C48'"></li>
    </ul>
    <p [coContent]="'DL.TF01.C49'"></p>
  </co-introbox>

  <ng-container *ngIf="pageStates.insuranceInitialized">
    <ng-container
      *ngIf="!pageStates.showHealthInformation && !pageStates.receipt"
    >
      <!-- ERROR MESSAGES -->
      <co-alert *ngIf="failedPolicies">
        <span
          [coContent]="'DL.TF01.C59'"
          [coContentSubst]="failedPolicies"
        ></span>
      </co-alert>

      <co-alert *ngIf="orderedChanges.harPersisteretIndhold">
        <span [coContent]="'DL.TF01.C53'"></span>
      </co-alert>

      <co-alert *ngIf="overlay.payoutInProgress">
        <p [coContent]="'DL.TF01.C70'"></p>
      </co-alert>

      <co-alert *ngIf="overlay.healthMissing">
        <span [coContent]="'DL.TF01.C60'"></span>
      </co-alert>

      <co-alert *ngIf="overlay.healthPending">
        <span [coContent]="'DL.TF01.C61'"></span>
      </co-alert>

      <co-alert
        *ngIf="
          overlay.locScale &&
          !overlay.insuranceGuideTooOld &&
          pensionCustomer.isRaadgiver
        "
      >
        <span [coContent]="'DL.TF01.C216'"></span>
      </co-alert>

      <co-alert *ngIf="overlay.policyBlocked && fromGuide">
        <h2 [coContent]="'DL.TF01.C136'"></h2>
        <p [coContent]="'DL.TF01.C137'"></p>
      </co-alert>

      <!-- INFO MESSAGES -->
      <co-alert
        class="alert-info"
        *ngIf="orderedChanges.harIkkePersisteretIndhold"
      >
        <span [coContent]="'DL.TF01.C54'"></span>
      </co-alert>

      <co-alert
        class="alert-info"
        *ngIf="pensionCustomer.pensionInfoExcludedPolicies"
      >
        <span [coContent]="'DL.PI01.C147'"></span>
      </co-alert>

      <co-alert
        class="alert-info"
        *ngIf="
          !pensionCustomer.harPensionsinfo ||
          pensionCustomer.pensionInfoDataInvalid
        "
      >
        <span [coContent]="'DL.PI01.C154'"></span>
      </co-alert>
    </ng-container>

    <!-- INSURANCE GUIDE "COMMERCIAL" -->
    <co-accordion
      [title]="'DL.FG01.C1961'"
      [initExpanded]="
        insuranceGuideChecks.guideTooOld || insuranceGuideChecks.noGuide
      "
      *ngIf="
        !pageStates.receipt &&
        !overlay.show &&
        insuranceGuideChecks.showGuideWarning
      "
    >
      <div class="insurance-guide-banner">
        <div class="insurance-guide-info">
          <div
            *ngIf="insuranceGuideChecks.guideNotQuiteTooOld"
            [coContent]="'DL.FG01.C1463'"
          ></div>
          <div
            *ngIf="insuranceGuideChecks.guideTooOld"
            [coContent]="'DL.FG01.C1464'"
          ></div>
          <div
            *ngIf="insuranceGuideChecks.noGuide"
            [coContent]="'DL.FG01.C1466'"
          ></div>
        </div>
        <div class="insurance-guide-button-container">
          <div class="insurance-guide-button">
            <button
              mat-raised-button
              [routerLink]="'/mineforsikringer/forsikringsguide'"
              [coContent]="'DL.FG01.C1465'"
            ></button>
            <div
              class="button-note"
              [coContent]="insuranceGuideChecks.guideDate"
            ></div>
          </div>
        </div>
      </div>
    </co-accordion>
  </ng-container>

  <co-loading [show]="!pageStates.insuranceInitialized"></co-loading>

  <div
    *ngIf="
      pageStates.insuranceInitialized &&
      !pageStates.showHealthInformation &&
      !pageStates.receipt
    "
    class="spacing-unit--double-over"
  >
    <!-- LOSS OF OCCOPATIONAL CAPACITY -->
    <div class="row" #scrollTargetLoc>
      <div class="col">
        <h2 [coContent]="'DL.TF01.C25'">TAE</h2>
        <div coContent="DL.FG01.C1852"></div>

        <co-alert
          class="alert-info spacing-unit--over"
          *ngIf="hasLocOnMultiplePolicies"
        >
          <div
            [coContent]="'DL.TF01.C213'"
            [coContentSubst]="
              insuranceState.insurance.tilpasForsikringstal.policenummer
            "
          ></div>
        </co-alert>

        <mitpfa-insurance-adjustment-advisor-info
          *ngIf="
            !overlay.policyBlocked && insuranceState.pensionCustomer.isRaadgiver
          "
          [adjustmentAdvisorInfo]="locAdjustmentAdvisorInfo"
          [reset]="insuranceAdjustmentResetService.locResetAnnounced$"
          [insuranceState]="insuranceState"
          [error]="advisorDataError"
          [pensionCustomer]="this.pensionCustomer"
          (updated)="updateLocAdvisorInfo($event)"
          (taxCodeUpdated)="updateLocTaxCodeAdvisorInfo($event)"
          (applyCorrections)="applyAdvisorCorrections($event)"
          (applyManualCorrections)="applyManualAdvisorCorrections()"
          (deleted)="deleteLocAdvisorInfo()"
        >
        </mitpfa-insurance-adjustment-advisor-info>
        <div
          *ngIf="
            !insuranceGuideChecks.guideTooOld &&
            !insuranceGuideChecks.noGuide &&
            confirmChoiceInitialized
          "
        >
          <co-card class="card--slim center-box" [allowOverflow]="true">
            <mitpfa-insurance-guide-recommendation-occupational-capacity
              [pensionInfo]="insuranceStateOriginal.pensionInfo"
              [advisorData]="locAdjustmentAdvisorInfo.advisorData"
              [financialSituation]="insuranceStateOriginal.financialSituation"
              [insuranceDetails]="insuranceStateOriginal.insurance"
              [insuranceOverview]="insuranceStateOriginal.insuranceOverview"
              [salary]="salary"
              [recommendationHeaderTooltip]="'DL.FG01.I605A'"
              [showFollowOurRecommendationButton]="
                locInputConfig.showRecommendationButton
              "
              [hideActions]="overlay.policyBlocked || disabledElements.page"
              [confirmChoice]="confirmChoice"
              (confirmChoiceEmitter)="onConfirmChoiceLoc(true)"
            ></mitpfa-insurance-guide-recommendation-occupational-capacity>
          </co-card>
        </div>
      </div>
    </div>

    <div #locInputAccordion></div>
    <co-accordion
      *ngIf="!overlay.policyBlocked"
      class="accordion--no-padding"
      [title]="'DL.TF01.C209'"
      [initExpanded]="inputExpandedLoc"
      [expanded]="inputExpandedLoc"
      accordionName="pfaloc configuration"
      (emitExpand)="inputExpandedLoc = $event"
    >
      <mitpfa-insurance-adjustment-input-loc
        [reset]="insuranceAdjustmentResetService.locResetAnnounced$"
        [inputConfig]="locInputConfig"
        [tags]="tags"
        [disabled]="disabledElements.page"
        [autoAdjustmentType]="
          insuranceState.insurance.tilpasForsikringstal?.autoAdjustment?.type
        "
        [hideActions]="overlay.policyBlocked || disabledElements.page"
        [confirmChoice]="confirmChoice"
        [insuranceGuideChecks]="insuranceGuideChecks"
        (confirmChoiceEmitter)="onConfirmChoiceLoc(false)"
        [calculatedByUser]="calculatedByUser"
        [showAuaConditions]="showAuaConditions"
        (changes)="onUserInput($event)"
      ></mitpfa-insurance-adjustment-input-loc>
    </co-accordion>

    <co-spacer-section></co-spacer-section>

    <!-- CRITICAL ILLNESS -->
    <div
      #scrollTargetCi
      *ngIf="
        insuranceState.insurance.tilpasForsikringstal
          .daekningAendringKritiskSygdomSelv.skalVises ||
        insuranceState.insurance.tilpasForsikringstal
          .daekningAendringKritiskSygdomBoern.skalVises
      "
    >
      <div class="row">
        <div class="col">
          <h2 [coContent]="'DL.TF01.C27'">KS</h2>
          <div coContent="DL.FG01.C1860"></div>
          <co-card
            class="card--slim center-box"
            [allowOverflow]="true"
            *ngIf="
              !insuranceGuideChecks.guideTooOld &&
              !insuranceGuideChecks.noGuide &&
              confirmChoiceInitialized
            "
          >
            <mitpfa-insurance-guide-recommendation-critical-illness
              #criticalIllness
              [financialSituation]="insuranceState.financialSituation"
              [insuranceDetails]="insuranceState.insurance"
              [insuranceOverview]="insuranceStateOriginal.insuranceOverview"
              [salary]="salary"
              [showFollowOurRecommendationButton]="
                ciInputConfig.showRecommendationButton
              "
              [hideActions]="overlay.policyBlocked || disabledElements.page"
              [confirmChoice]="confirmChoice"
              (confirmChoiceEmitter)="onConfirmChoiceCi(true)"
            ></mitpfa-insurance-guide-recommendation-critical-illness>
          </co-card>
        </div>
      </div>

      <div #ciInputAccordion></div>
      <co-accordion
        *ngIf="!overlay.policyBlocked"
        class="accordion--no-padding"
        [title]="'DL.TF01.C209'"
        [initExpanded]="inputExpandedCi"
        [expanded]="inputExpandedCi"
        accordionName="pfaci configuration"
        (emitExpand)="inputExpandedCi = $event"
      >
        <mitpfa-insurance-adjustment-input-ci
          [reset]="insuranceAdjustmentResetService.ciResetAnnounced$"
          [updated]="insuranceAdjustmentUpdateService.ciUpdateAnnounced$"
          [inputConfig]="ciInputConfig"
          [insuranceOverview]="insuranceState.insuranceOverview"
          [disabled]="disabledElements.page"
          [insuranceGuideChecks]="insuranceGuideChecks"
          [hideActions]="overlay.policyBlocked || disabledElements.page"
          [confirmChoice]="confirmChoice"
          (confirmChoiceEmitter)="onConfirmChoiceCi(false)"
          (changes)="onUserInput($event)"
        ></mitpfa-insurance-adjustment-input-ci>
      </co-accordion>
      <co-spacer-section></co-spacer-section>
    </div>

    <!-- LIFE -->
    <div class="row" #scrollTargetLife>
      <div class="col">
        <h2 [coContent]="'DL.TF01.C26'">LIV</h2>
        <div coContent="DL.FG01.C1833"></div>
        <mitpfa-insurance-adjustment-advisor-info
          *ngIf="
            !overlay.policyBlocked && insuranceState.pensionCustomer.isRaadgiver
          "
          [adjustmentAdvisorInfo]="lifeAdjustmentAdvisorInfo"
          [reset]="insuranceAdjustmentResetService.lifeResetAnnounced$"
          [insuranceState]="insuranceState"
          [error]="advisorDataError"
          [pensionCustomer]="this.pensionCustomer"
          (updated)="updateLifeAdvisorInfo($event)"
          (taxCodeUpdated)="updateLifeTaxCodeAdvisorInfo($event)"
          (applyCorrections)="applyAdvisorCorrections($event)"
          (applyManualCorrections)="applyManualAdvisorCorrections()"
          (deleted)="deleteLifeAdvisorInfo()"
        >
        </mitpfa-insurance-adjustment-advisor-info>
        <div
          *ngIf="
            !insuranceGuideChecks.guideTooOld &&
            !insuranceGuideChecks.noGuide &&
            confirmChoiceInitialized
          "
        >
          <co-card class="card--slim center-box" [allowOverflow]="true">
            <mitpfa-insurance-guide-recommendation-life
              [pensionInfo]="insuranceStateOriginal.pensionInfo"
              [advisorData]="lifeAdjustmentAdvisorInfo.advisorData"
              [financialSituation]="insuranceStateOriginal.financialSituation"
              [insuranceDetails]="insuranceStateOriginal.insurance"
              [familyInformation]="insuranceStateOriginal.familyInformation"
              [salary]="salary"
              [recommendationHeaderTooltip]="'DL.FG01.I605B'"
              [showFollowOurRecommendationButton]="
                lifeInputConfig.showRecommendationButton
              "
              [hideActions]="overlay.policyBlocked || disabledElements.page"
              [confirmChoice]="confirmChoice"
              (confirmChoiceEmitter)="onConfirmChoiceLife(true)"
            ></mitpfa-insurance-guide-recommendation-life>
          </co-card>
        </div>
      </div>
    </div>

    <div #lifeInputAccordion></div>
    <co-accordion
      *ngIf="!overlay.policyBlocked"
      class="accordion--no-padding"
      [title]="'DL.TF01.C209'"
      [initExpanded]="inputExpandedLife"
      [expanded]="inputExpandedLife"
      accordionName="pfalife configuration"
      (emitExpand)="inputExpandedLife = $event"
    >
      <mitpfa-insurance-adjustment-input-life
        [reset]="insuranceAdjustmentResetService.lifeResetAnnounced$"
        [inputConfig]="lifeInputConfig"
        [inputNotification]="lifeInputNotification"
        [lifeUpdateNote]="lifeUpdateNote"
        [pensionPlanPolice]="pensionPlanPolice"
        [disabled]="disabledElements.page"
        [insuranceGuideChecks]="insuranceGuideChecks"
        [hideActions]="overlay.policyBlocked || disabledElements.page"
        [confirmChoice]="confirmChoice"
        [showAuaConditions]="showAuaConditions"
        (confirmChoiceEmitter)="onConfirmChoiceLife(false)"
        (changes)="onUserInput($event)"
      ></mitpfa-insurance-adjustment-input-life>
    </co-accordion>

    <co-sticky-bar
      [nonSticyBreakpoint]="875"
      *ngIf="
        !disabledElements.page &&
        !overlay.policyBlocked &&
        (unsavedChanges ||
          (addedToBasket &&
            !insuranceState.newBusinessAdvisory.digitalSalgRaadgivning))
      "
    >
      <div class="call-to-action-bar">
        <div class="alert-box">
          <co-alert *ngIf="!considered.all" class="alert-info">
            <span [coContent]="'DL.TF01.C212'"></span>
            <span *ngIf="notConsideredTypeOne">&nbsp;</span>
            <a
              *ngIf="notConsideredTypeOne"
              [coContent]="notConsideredTypeOne"
              (click)="scrollToSection(notConsideredTypeOneScrollTo)"
              class="link"
            ></a>
            <span *ngIf="notConsideredTypeTwo" coContent="DL.TF01.C214"></span>
            <a
              *ngIf="notConsideredTypeTwo"
              [coContent]="notConsideredTypeTwo"
              (click)="scrollToSection(notConsideredTypeTwoScrollTo)"
              class="link"
            ></a>
          </co-alert>
          <co-icon-with-text *ngIf="considered.all">
            <co-icon-circle-complete icon></co-icon-circle-complete>
            <span body coContent="DL.TF01.C215"></span>
          </co-icon-with-text>
        </div>
        <div class="button-bar">
          <button
            [disabled]="disabledElements.buttonReset"
            mat-raised-button
            [coContent]="'DL.TF01.C38'"
            (click)="onReset()"
            class="button-primary--level3"
          >
            Nulstil
          </button>
          <button
            *ngIf="
              !overlay.locScale &&
              (insuranceState.pensionCustomer.isRaadgiver ||
                orderedChanges?.harIkkePersisteretIndholdIKurv)
            "
            [disabled]="disabledElements.buttonAddToBasket"
            mat-raised-button
            [coContent]="'DL.TF01.C39'"
            (click)="onAddToBasket()"
            class="button-secondary"
          >
            Læg i kurv
          </button>
          <button
            *ngIf="
              !insuranceState.newBusinessAdvisory.digitalSalgRaadgivning &&
              !overlay.locScale
            "
            [disabled]="disabledElements.buttonApprove"
            mat-raised-button
            [coContent]="'DL.TF01.C40'"
            (click)="onApprove()"
          >
            Godkend
          </button>
          <co-warning *ngIf="errors.addToBasket">
            <span [coContent]="'DL.MD01.C160'"></span>
          </co-warning>
          <co-warning *ngIf="errors.approve">
            <span [coContent]="'DL.MD01.C161'"></span>
          </co-warning>
        </div>
      </div>
      <div
        class="align--right spacing-unit--half-over"
        *ngIf="
          addedToBasket &&
          !insuranceState.newBusinessAdvisory.digitalSalgRaadgivning
        "
      >
        <span [coContent]="'DL.TF01.C51'"></span>
      </div>
    </co-sticky-bar>
  </div>

  <mitpfa-insurance-adjustment-health-information
    (back)="healthInformationBack()"
    (signingCompleted)="onSigningCompleted($event)"
    [orderItems]="getOrderItems()"
    [signingFunction]="getSigningFunction()"
    *ngIf="
      pageStates.showHealthInformation &&
      !pageStates.receipt &&
      pageStates.insuranceInitialized
    "
  ></mitpfa-insurance-adjustment-health-information>

  <mitpfa-insurance-adjustment-receipt
    *ngIf="pageStates.receipt"
    [healthQuestionnaire]="pageStates.showHealthInformation"
    [godkendSvar]="godkendSvar"
    [isOnboarding]="isOnboarding"
    (exit)="finish()"
    [adviser]="insuranceState.pensionCustomer.isRaadgiver"
  ></mitpfa-insurance-adjustment-receipt>
</div>
