import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        IncludeExternalPolicies,
        IncludeExternalPoliciesSchema
} from '../model/models';

import { PensioninfoService } from './pensioninfo.service';


@Injectable({
    providedIn: 'root'
})
export class PensioninfoStore extends GenericStore {

            private pensioninfohentPDFPartnerDokumentIndexCache: Map<string, RestCacheEntry<Blob>> = new Map<string, RestCacheEntry<Blob>>();
            private pensioninfohentPDFDokumentIndexCache: Map<string, RestCacheEntry<Blob>> = new Map<string, RestCacheEntry<Blob>>();

    constructor(private pensioninfoService: PensioninfoService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidatepensioninfohentPDFDokumentIndex();
                
                this.invalidatepensioninfohentPDFPartnerDokumentIndex();
                
                
    }


                public pensioninfoHentPDFDokumentGet() : Observable<Blob> {
                    let hash = "0";
                    if (this.pensioninfohentPDFDokumentIndexCache.has(hash)) {
                        return this.pensioninfohentPDFDokumentIndexCache.get(hash)!.observable;
                    } else {
                        let pensioninfohentPDFDokumentIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let pensioninfohentPDFDokumentIndex = this.initiateStream(pensioninfohentPDFDokumentIndexSubject, this.pensioninfoService.pensioninfoHentPDFDokumentGet(), JoiObj.any());
                        this.pensioninfohentPDFDokumentIndexCache.set(hash, new RestCacheEntry<Blob>(pensioninfohentPDFDokumentIndex, pensioninfohentPDFDokumentIndexSubject));
                        return pensioninfohentPDFDokumentIndex;
                    }
                }

                public invalidatepensioninfohentPDFDokumentIndex() {
                    this.pensioninfohentPDFDokumentIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






                public pensioninfoHentPDFPartnerDokumentGet() : Observable<Blob> {
                    let hash = "0";
                    if (this.pensioninfohentPDFPartnerDokumentIndexCache.has(hash)) {
                        return this.pensioninfohentPDFPartnerDokumentIndexCache.get(hash)!.observable;
                    } else {
                        let pensioninfohentPDFPartnerDokumentIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let pensioninfohentPDFPartnerDokumentIndex = this.initiateStream(pensioninfohentPDFPartnerDokumentIndexSubject, this.pensioninfoService.pensioninfoHentPDFPartnerDokumentGet(), JoiObj.any());
                        this.pensioninfohentPDFPartnerDokumentIndexCache.set(hash, new RestCacheEntry<Blob>(pensioninfohentPDFPartnerDokumentIndex, pensioninfohentPDFPartnerDokumentIndexSubject));
                        return pensioninfohentPDFPartnerDokumentIndex;
                    }
                }

                public invalidatepensioninfohentPDFPartnerDokumentIndex() {
                    this.pensioninfohentPDFPartnerDokumentIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }








                public pensioninfoSaveIncludeExternalPoliciesPost(includeExternalPolicies?: IncludeExternalPolicies) : Observable<any> {
                    return this.pensioninfoService.pensioninfoSaveIncludeExternalPoliciesPost(includeExternalPolicies).pipe(
                        map(value => {
                            
                            
                            return value;
                        })
                    );
                }




}