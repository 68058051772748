import {
  Component,
  OnInit,
  OnDestroy,
  inject,
  DestroyRef
} from '@angular/core';
import { map, Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SessionTimeoutModalComponent } from './session-timeout-modal.component';
import { PopupStyle } from '@pfa/models';
import {
  BrugerStore,
  DigitalSalgContext,
  DigitalsalgService,
  PensionskundeStore
} from '@pfa/gen';
import { HttpResponse } from '@angular/common/http';
import {
  SessionTimeoutService,
  SessionTimeoutExtenderService
} from '@pfa/core';
import { switchMap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ContactRemindComponent } from '@pfaform';
import { Event, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'co-session-timeout-extender',
  template: ''
})
export class SessionTimeoutComponent implements OnInit, OnDestroy {
  private openDialog$: Subscription;
  private isDialogOpen: boolean;
  private timeout: Subscription;
  private initialized: boolean;
  private isAdvisor: boolean;
  private isAdvisorCheckReady: boolean;

  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly contactRemindPopup: MatDialogRef<ContactRemindComponent> =
    inject(MatDialogRef<ContactRemindComponent>);
  private readonly router: Router = inject(Router);
  private readonly sessionTimeoutService: SessionTimeoutService = inject(
    SessionTimeoutService
  );
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly sessionTimeoutExtenderService: SessionTimeoutExtenderService =
    inject(SessionTimeoutExtenderService);
  private readonly userStore: BrugerStore = inject(BrugerStore);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly digitalSalgService: DigitalsalgService =
    inject(DigitalsalgService);

  ngOnInit(): void {
    this.setupAppTimeout();

    this.router.events
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(event => {
        this.handleNavigationStart(event);
      });

    this.pensionskundeStore
      .pensionskundeGet()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: pk => {
          this.isAdvisor = pk.isRaadgiver;
        }
      });

    this.sessionTimeoutService
      .onTimeout()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.handleTimedOut();
        },
        error: () => {}
      });
  }

  private setupAppTimeout(): void {
    sessionStorage.removeItem('userTimedOut');
    sessionStorage.removeItem('userTimedOutRaadgiver');
    this.isAdvisorCheckReady = true;
    this.sessionTimeoutService.start();
    this.extendTimeout();
  }

  private handleNavigationStart(event: Event): void {
    if (event instanceof NavigationStart) {
      if (this.isAdvisorCheckReady) {
        this.sessionTimeoutExtenderService.extendTimeout();
      }
    }
  }

  private extendTimeout() {
    this.digitalSalgService
      .digitalsalgRaadgivningGet('response')
      .pipe(
        map((data: HttpResponse<DigitalSalgContext>) => {
          this.sessionTimeoutService.setSecondsBeforeTimeoutISAM(
            this.extractSessionTimeout(data.headers.get('Session-Timeout'))
          );
        }),
        switchMap(() => this.userStore.brugerGet()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(user => {
        if (!this.initialized && user) {
          this.sessionTimeoutExtenderService.startTimer();
          this.openDialog$ = this.sessionTimeoutExtenderService
            .getOpenDialog()
            .subscribe(data => {
              if (data && !this.isDialogOpen) {
                this.openDialog();
              }
              if (!data) {
                // successfully extended timeout
                this.isDialogOpen = false;
              }
            });
        }
        this.initialized = true;
      });
  }

  private handleTimedOut(): void {
    this.sessionTimeoutExtenderService.getExtenderDialog()?.close();
    if (this.contactRemindPopup?.close instanceof Function) {
      this.contactRemindPopup.close();
    }
    sessionStorage.setItem('userTimedOut', 'true');

    if (this.isAdvisor) {
      sessionStorage.setItem('userTimedOutRaadgiver', 'true');
    }

    this.sessionTimeoutExtenderService.stopTimer();
    this.sessionTimeoutService.stop();
    this.router.navigate(['/logout']);
  }

  private extractSessionTimeout(sessionTimeoutStr: string): number {
    if (sessionTimeoutStr) {
      try {
        return parseInt(sessionTimeoutStr, 10);
      } catch (e) {
        console.error(
          'SessionTimeoutService.fetchTimeoutSetting() failing due to: ' + e
        );
      }
    }
    return this.toSeconds(21);
  }

  private toSeconds(minutes: number): number {
    return minutes * 60;
  }

  public ngOnDestroy(): void {
    this.sessionTimeoutExtenderService.stopTimer();
    this.timeout.unsubscribe();
    this.openDialog$.unsubscribe();
  }

  public openDialog(): void {
    this.isDialogOpen = true;
    const dialog = this.dialog.open(SessionTimeoutModalComponent, {
      panelClass: PopupStyle.PopupSmall,
      autoFocus: false,
      disableClose: true
    });
    this.sessionTimeoutExtenderService.setExtenderDialog(dialog);
    dialog.afterClosed().subscribe(() => {
      this.sessionTimeoutExtenderService.setExtenderDialog(undefined);
    });
  }
}
