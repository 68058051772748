<co-accordion [title]="'DL.TR01.C14'">
  <co-agreement-and-payment
    [overview]="overview"
    [advisorOptions]="advisorOptions"
    [savePaymentsFail]="savePaymentsFail"
    (savePayments)="savePayments.emit($event)"
    (saveVoluntaryPayment)="saveVoluntaryPayment.emit($event)"
    [saveIndbetalingSikringFail]="saveIndbetalingSikringFail"
    (saveIndbetalingSikring)="saveIndbetalingSikring.emit($event)"
  ></co-agreement-and-payment>
</co-accordion>
<co-accordion [title]="'DL.TR01.C49'">
  <co-investment [overview]="overview"></co-investment>
</co-accordion>
<co-accordion [title]="'DL.TR01.C75'">
  <co-insurance
    [overview]="overview"
    [advisorOptions]="advisorOptions"
    [switchHsFail]="switchHsFail"
    (partialSchemeSelected)="this.partialSchemeSelected.emit($event)"
  ></co-insurance>
</co-accordion>
<co-accordion
  *ngIf="
    overview.policyType === 'CONTINUATION' &&
    overview.viderefoerelseSammenligning.existingPolicyOverdue !== true
  "
>
  <div class="header" customHeader>
    <div class="text--bold" coContent="DL.TR01.C384"></div>
    <div
      *ngIf="overview.viderefoerelseSammenligning.risikoForhoejet"
      class="riskincreased"
    >
      <co-icon-error></co-icon-error>
      <span coContent="DL.TR01.C489"></span>
    </div>
  </div>
  <co-continuation-comparison
    [overview]="overview"
  ></co-continuation-comparison>
</co-accordion>
<co-accordion [title]="'DL.TR01.C421'">
  <co-prices-new-offer [overview]="overview"></co-prices-new-offer>
</co-accordion>
