import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        KontaktMobil,
        KontaktMobilSchema,
        PensionsplanTile,
        PensionsplanTileSchema,
        SuccessResponse,
        SuccessResponseSchema
} from '../model/models';

import { PensionsplanService } from './pensionsplan.service';


@Injectable({
    providedIn: 'root'
})
export class PensionsplanStore extends GenericStore {

            private pensionsplantileIndexCache: Map<string, RestCacheEntry<PensionsplanTile>> = new Map<string, RestCacheEntry<PensionsplanTile>>();

    constructor(private pensionsplanService: PensionsplanService) {
        super();
    }

    invalidateAll(): void {
                
                
                
                this.invalidatepensionsplantileIndex();
    }




                public pensionsplanKontaktPost(kontaktMobil?: KontaktMobil) : Observable<SuccessResponse> {
                    return this.pensionsplanService.pensionsplanKontaktPost(kontaktMobil).pipe(
                        map(value => {
                            
                            
                                    this.throwOrHandleValidationError(SuccessResponseSchema, value);
                            return value;
                        })
                    );
                }




                public pensionsplanTileGet() : Observable<PensionsplanTile> {
                    let hash = "0";
                    if (this.pensionsplantileIndexCache.has(hash)) {
                        return this.pensionsplantileIndexCache.get(hash)!.observable;
                    } else {
                        let pensionsplantileIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let pensionsplantileIndex = this.initiateStream(pensionsplantileIndexSubject, this.pensionsplanService.pensionsplanTileGet(), PensionsplanTileSchema);
                        this.pensionsplantileIndexCache.set(hash, new RestCacheEntry<PensionsplanTile>(pensionsplantileIndex, pensionsplantileIndexSubject));
                        return pensionsplantileIndex;
                    }
                }

                public invalidatepensionsplantileIndex() {
                    this.pensionsplantileIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






}