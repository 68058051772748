import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        CustomerProfile,
        CustomerProfileSchema
} from '../model/models';

import { CustomerprofileService } from './customerprofile.service';


@Injectable({
    providedIn: 'root'
})
export class CustomerprofileStore extends GenericStore {

            private customerprofileIndexCache: Map<string, RestCacheEntry<CustomerProfile>> = new Map<string, RestCacheEntry<CustomerProfile>>();

    constructor(private customerprofileService: CustomerprofileService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidatecustomerprofileIndex();
                
                
    }


                public customerprofileGet() : Observable<CustomerProfile> {
                    let hash = "0";
                    if (this.customerprofileIndexCache.has(hash)) {
                        return this.customerprofileIndexCache.get(hash)!.observable;
                    } else {
                        let customerprofileIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let customerprofileIndex = this.initiateStream(customerprofileIndexSubject, this.customerprofileService.customerprofileGet(), CustomerProfileSchema);
                        this.customerprofileIndexCache.set(hash, new RestCacheEntry<CustomerProfile>(customerprofileIndex, customerprofileIndexSubject));
                        return customerprofileIndex;
                    }
                }

                public invalidatecustomerprofileIndex() {
                    this.customerprofileIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }









                public customerprofileMitpfaRedirectPut() : Observable<any> {
                    return this.customerprofileService.customerprofileMitpfaRedirectPut().pipe(
                        map(value => {
                            
                            
                            return value;
                        })
                    );
                }



}