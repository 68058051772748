import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        PrisGebyrOversigt,
        PrisGebyrOversigtSchema,
        Prisliste,
        PrislisteSchema
} from '../model/models';

import { PrislisteService } from './prisliste.service';


@Injectable({
    providedIn: 'root'
})
export class PrislisteStore extends GenericStore {

            private prislisteidShowCache: Map<string, RestCacheEntry<Prisliste>> = new Map<string, RestCacheEntry<Prisliste>>();
            private prislisteIndexCache: Map<string, RestCacheEntry<PrisGebyrOversigt>> = new Map<string, RestCacheEntry<PrisGebyrOversigt>>();

    constructor(private prislisteService: PrislisteService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidateprislisteIndex();
                this.invalidateprislisteidShow();
                
    }


                public prislisteGet() : Observable<PrisGebyrOversigt> {
                    let hash = "0";
                    if (this.prislisteIndexCache.has(hash)) {
                        return this.prislisteIndexCache.get(hash)!.observable;
                    } else {
                        let prislisteIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let prislisteIndex = this.initiateStream(prislisteIndexSubject, this.prislisteService.prislisteGet(), PrisGebyrOversigtSchema);
                        this.prislisteIndexCache.set(hash, new RestCacheEntry<PrisGebyrOversigt>(prislisteIndex, prislisteIndexSubject));
                        return prislisteIndex;
                    }
                }

                public invalidateprislisteIndex() {
                    this.prislisteIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }





                public prislisteIdGet(id: string) : Observable<Prisliste> {
                    let hash = this.hashKey((id.toString() || "0"));
                    if (this.prislisteidShowCache.has(hash)) {
                        return this.prislisteidShowCache.get(hash)!.observable;
                    } else {
                        let prislisteidShowSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let prislisteidShow = this.initiateStream(prislisteidShowSubject, this.prislisteService.prislisteIdGet(id), PrislisteSchema);
                        this.prislisteidShowCache.set(hash, new RestCacheEntry<Prisliste>(prislisteidShow, prislisteidShowSubject));
                        return prislisteidShow;
                    }
                }

                public invalidateprislisteidShowById(id: string) {
                    let hash = this.hashKey((id.toString() || "0"));
                    if (this.prislisteidShowCache.has(hash)) {
                        this.prislisteidShowCache.get(hash)!.subject.next(0);
                    }
                }

                public invalidateprislisteidShow() {
                    this.prislisteidShowCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }







}