import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        ForsikringDetaljer,
        ForsikringDetaljerSchema,
        SprogType,
        SprogTypeSchema
} from '../model/models';

import { ForsikringsguideService } from './forsikringsguide.service';


@Injectable({
    providedIn: 'root'
})
export class ForsikringsguideStore extends GenericStore {


    constructor(private forsikringsguideService: ForsikringsguideService) {
        super();
    }

    invalidateAll(): void {
                
                
    }




                public forsikringsguideRapportPost(sprog: SprogType,skip?: boolean,forsikringDetaljer?: ForsikringDetaljer) : Observable<any> {
                    return this.forsikringsguideService.forsikringsguideRapportPost(sprog,skip,forsikringDetaljer).pipe(
                        map(value => {
                            
                            
                            return value;
                        })
                    );
                }




}