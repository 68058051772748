import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        Forsikringsoversigt,
        ForsikringsoversigtSchema,
        LanguageCode,
        LanguageCodeSchema,
        VariantDetail,
        VariantDetailSchema
} from '../model/models';

import { ForsikringsoversigtService } from './forsikringsoversigt.service';


@Injectable({
    providedIn: 'root'
})
export class ForsikringsoversigtStore extends GenericStore {

            private forsikringsoversigtproductVariantsIndexCache: Map<string, RestCacheEntry<Array<VariantDetail>>> = new Map<string, RestCacheEntry<Array<VariantDetail>>>();
            private forsikringsoversigtdetaljerIndexCache: Map<string, RestCacheEntry<Forsikringsoversigt>> = new Map<string, RestCacheEntry<Forsikringsoversigt>>();

    constructor(private forsikringsoversigtService: ForsikringsoversigtService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidateforsikringsoversigtdetaljerIndex();
                
                this.invalidateforsikringsoversigtproductVariantsIndex();
    }


                public forsikringsoversigtDetaljerGet() : Observable<Forsikringsoversigt> {
                    let hash = "0";
                    if (this.forsikringsoversigtdetaljerIndexCache.has(hash)) {
                        return this.forsikringsoversigtdetaljerIndexCache.get(hash)!.observable;
                    } else {
                        let forsikringsoversigtdetaljerIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let forsikringsoversigtdetaljerIndex = this.initiateStream(forsikringsoversigtdetaljerIndexSubject, this.forsikringsoversigtService.forsikringsoversigtDetaljerGet(), ForsikringsoversigtSchema);
                        this.forsikringsoversigtdetaljerIndexCache.set(hash, new RestCacheEntry<Forsikringsoversigt>(forsikringsoversigtdetaljerIndex, forsikringsoversigtdetaljerIndexSubject));
                        return forsikringsoversigtdetaljerIndex;
                    }
                }

                public invalidateforsikringsoversigtdetaljerIndex() {
                    this.forsikringsoversigtdetaljerIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






                public forsikringsoversigtProductVariantsGet(language?: LanguageCode,variantIds?: Array<string>,hasTerms?: boolean,hasCoverages?: boolean) : Observable<Array<VariantDetail>> {
                    let hash = this.hashKey((language?.toString() || "0") + (variantIds?.toString() || "0") + (hasTerms?.toString() || "0") + (hasCoverages?.toString() || "0"));
                    if (this.forsikringsoversigtproductVariantsIndexCache.has(hash)) {
                        return this.forsikringsoversigtproductVariantsIndexCache.get(hash)!.observable;
                    } else {
                        let forsikringsoversigtproductVariantsIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let forsikringsoversigtproductVariantsIndex = this.initiateStream(forsikringsoversigtproductVariantsIndexSubject, this.forsikringsoversigtService.forsikringsoversigtProductVariantsGet(language,variantIds,hasTerms,hasCoverages), VariantDetailSchema);
                        this.forsikringsoversigtproductVariantsIndexCache.set(hash, new RestCacheEntry<Array<VariantDetail>>(forsikringsoversigtproductVariantsIndex, forsikringsoversigtproductVariantsIndexSubject));
                        return forsikringsoversigtproductVariantsIndex;
                    }
                }

                public invalidateforsikringsoversigtproductVariantsIndex() {
                    this.forsikringsoversigtproductVariantsIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






}