import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        CustomerOptions,
        CustomerOptionsSchema,
        PolicyReusableForOffer,
        PolicyReusableForOfferSchema
} from '../model/models';

import { IndividualcustomerprofileService } from './individualcustomerprofile.service';


@Injectable({
    providedIn: 'root'
})
export class IndividualcustomerprofileStore extends GenericStore {

            private individualcustomerprofilepoliciesavailableforofferIndexCache: Map<string, RestCacheEntry<Array<PolicyReusableForOffer>>> = new Map<string, RestCacheEntry<Array<PolicyReusableForOffer>>>();
            private individualcustomerprofilecustomeroptionsIndexCache: Map<string, RestCacheEntry<CustomerOptions>> = new Map<string, RestCacheEntry<CustomerOptions>>();

    constructor(private individualcustomerprofileService: IndividualcustomerprofileService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidateindividualcustomerprofilecustomeroptionsIndex();
                
                this.invalidateindividualcustomerprofilepoliciesavailableforofferIndex();
    }


                public individualcustomerprofileCustomeroptionsGet() : Observable<CustomerOptions> {
                    let hash = "0";
                    if (this.individualcustomerprofilecustomeroptionsIndexCache.has(hash)) {
                        return this.individualcustomerprofilecustomeroptionsIndexCache.get(hash)!.observable;
                    } else {
                        let individualcustomerprofilecustomeroptionsIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let individualcustomerprofilecustomeroptionsIndex = this.initiateStream(individualcustomerprofilecustomeroptionsIndexSubject, this.individualcustomerprofileService.individualcustomerprofileCustomeroptionsGet(), CustomerOptionsSchema);
                        this.individualcustomerprofilecustomeroptionsIndexCache.set(hash, new RestCacheEntry<CustomerOptions>(individualcustomerprofilecustomeroptionsIndex, individualcustomerprofilecustomeroptionsIndexSubject));
                        return individualcustomerprofilecustomeroptionsIndex;
                    }
                }

                public invalidateindividualcustomerprofilecustomeroptionsIndex() {
                    this.individualcustomerprofilecustomeroptionsIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






                public individualcustomerprofilePoliciesavailableforofferGet(globalId: string,aftaleId: string,activationDate: number) : Observable<Array<PolicyReusableForOffer>> {
                    let hash = this.hashKey((globalId.toString() || "0") + (aftaleId.toString() || "0") + (activationDate.toString() || "0"));
                    if (this.individualcustomerprofilepoliciesavailableforofferIndexCache.has(hash)) {
                        return this.individualcustomerprofilepoliciesavailableforofferIndexCache.get(hash)!.observable;
                    } else {
                        let individualcustomerprofilepoliciesavailableforofferIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let individualcustomerprofilepoliciesavailableforofferIndex = this.initiateStream(individualcustomerprofilepoliciesavailableforofferIndexSubject, this.individualcustomerprofileService.individualcustomerprofilePoliciesavailableforofferGet(globalId,aftaleId,activationDate), PolicyReusableForOfferSchema);
                        this.individualcustomerprofilepoliciesavailableforofferIndexCache.set(hash, new RestCacheEntry<Array<PolicyReusableForOffer>>(individualcustomerprofilepoliciesavailableforofferIndex, individualcustomerprofilepoliciesavailableforofferIndexSubject));
                        return individualcustomerprofilepoliciesavailableforofferIndex;
                    }
                }

                public invalidateindividualcustomerprofilepoliciesavailableforofferIndex() {
                    this.individualcustomerprofilepoliciesavailableforofferIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






}