import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        DanPensionsRapportRequest,
        DanPensionsRapportRequestSchema,
        DanPensionsRapportSvar,
        DanPensionsRapportSvarSchema
} from '../model/models';

import { PensionsrapportService } from './pensionsrapport.service';


@Injectable({
    providedIn: 'root'
})
export class PensionsrapportStore extends GenericStore {


    constructor(private pensionsrapportService: PensionsrapportService) {
        super();
    }

    invalidateAll(): void {
                
                
                
                
    }




                public pensionsrapportGemmePost(danPensionsRapportRequest?: DanPensionsRapportRequest) : Observable<DanPensionsRapportSvar> {
                    return this.pensionsrapportService.pensionsrapportGemmePost(danPensionsRapportRequest).pipe(
                        map(value => {
                            
                            
                                    this.throwOrHandleValidationError(DanPensionsRapportSvarSchema, value);
                            return value;
                        })
                    );
                }






                public pensionsrapportRapportPost(danPensionsRapportRequest?: DanPensionsRapportRequest) : Observable<DanPensionsRapportSvar> {
                    return this.pensionsrapportService.pensionsrapportRapportPost(danPensionsRapportRequest).pipe(
                        map(value => {
                            
                            
                                    this.throwOrHandleValidationError(DanPensionsRapportSvarSchema, value);
                            return value;
                        })
                    );
                }




}