import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        ForsikringDetaljer,
        ForsikringDetaljerSchema
} from '../model/models';

import { ForsikringService } from './forsikring.service';


@Injectable({
    providedIn: 'root'
})
export class ForsikringStore extends GenericStore {

            private forsikringdetaljerIndexCache: Map<string, RestCacheEntry<ForsikringDetaljer>> = new Map<string, RestCacheEntry<ForsikringDetaljer>>();

    constructor(private forsikringService: ForsikringService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidateforsikringdetaljerIndex();
    }


                public forsikringDetaljerGet() : Observable<ForsikringDetaljer> {
                    let hash = "0";
                    if (this.forsikringdetaljerIndexCache.has(hash)) {
                        return this.forsikringdetaljerIndexCache.get(hash)!.observable;
                    } else {
                        let forsikringdetaljerIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let forsikringdetaljerIndex = this.initiateStream(forsikringdetaljerIndexSubject, this.forsikringService.forsikringDetaljerGet(), ForsikringDetaljerSchema);
                        this.forsikringdetaljerIndexCache.set(hash, new RestCacheEntry<ForsikringDetaljer>(forsikringdetaljerIndex, forsikringdetaljerIndexSubject));
                        return forsikringdetaljerIndex;
                    }
                }

                public invalidateforsikringdetaljerIndex() {
                    this.forsikringdetaljerIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






}