import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        Fordele,
        FordeleSchema
} from '../model/models';

import { FordeleService } from './fordele.service';


@Injectable({
    providedIn: 'root'
})
export class FordeleStore extends GenericStore {

            private fordeleIndexCache: Map<string, RestCacheEntry<Fordele>> = new Map<string, RestCacheEntry<Fordele>>();

    constructor(private fordeleService: FordeleService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidatefordeleIndex();
    }


                public fordeleGet() : Observable<Fordele> {
                    let hash = "0";
                    if (this.fordeleIndexCache.has(hash)) {
                        return this.fordeleIndexCache.get(hash)!.observable;
                    } else {
                        let fordeleIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let fordeleIndex = this.initiateStream(fordeleIndexSubject, this.fordeleService.fordeleGet(), FordeleSchema);
                        this.fordeleIndexCache.set(hash, new RestCacheEntry<Fordele>(fordeleIndex, fordeleIndexSubject));
                        return fordeleIndex;
                    }
                }

                public invalidatefordeleIndex() {
                    this.fordeleIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






}