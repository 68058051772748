import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
} from '../model/models';

import { LogoutService } from './logout.service';


@Injectable({
    providedIn: 'root'
})
export class LogoutStore extends GenericStore {

            private logoutIndexCache: Map<string, RestCacheEntry<string>> = new Map<string, RestCacheEntry<string>>();

    constructor(private logoutService: LogoutService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidatelogoutIndex();
    }


                public logoutGet() : Observable<string> {
                    let hash = "0";
                    if (this.logoutIndexCache.has(hash)) {
                        return this.logoutIndexCache.get(hash)!.observable;
                    } else {
                        let logoutIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let logoutIndex = this.initiateStream(logoutIndexSubject, this.logoutService.logoutGet(), JoiObj.any());
                        this.logoutIndexCache.set(hash, new RestCacheEntry<string>(logoutIndex, logoutIndexSubject));
                        return logoutIndex;
                    }
                }

                public invalidatelogoutIndex() {
                    this.logoutIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






}