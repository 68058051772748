import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        DigitalSalgContext,
        DigitalSalgContextSchema
} from '../model/models';

import { DigitalsalgService } from './digitalsalg.service';


@Injectable({
    providedIn: 'root'
})
export class DigitalsalgStore extends GenericStore {

            private digitalsalgraadgivningIndexCache: Map<string, RestCacheEntry<DigitalSalgContext>> = new Map<string, RestCacheEntry<DigitalSalgContext>>();

    constructor(private digitalsalgService: DigitalsalgService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidatedigitalsalgraadgivningIndex();
    }


                public digitalsalgRaadgivningGet() : Observable<DigitalSalgContext> {
                    let hash = "0";
                    if (this.digitalsalgraadgivningIndexCache.has(hash)) {
                        return this.digitalsalgraadgivningIndexCache.get(hash)!.observable;
                    } else {
                        let digitalsalgraadgivningIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let digitalsalgraadgivningIndex = this.initiateStream(digitalsalgraadgivningIndexSubject, this.digitalsalgService.digitalsalgRaadgivningGet(), DigitalSalgContextSchema);
                        this.digitalsalgraadgivningIndexCache.set(hash, new RestCacheEntry<DigitalSalgContext>(digitalsalgraadgivningIndex, digitalsalgraadgivningIndexSubject));
                        return digitalsalgraadgivningIndex;
                    }
                }

                public invalidatedigitalsalgraadgivningIndex() {
                    this.digitalsalgraadgivningIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






}