import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        ClimateFootprintPolicyData,
        ClimateFootprintPolicyDataSchema,
        ClimateFootprintResponse,
        ClimateFootprintResponseSchema
} from '../model/models';

import { ClimatefootprintService } from './climatefootprint.service';


@Injectable({
    providedIn: 'root'
})
export class ClimatefootprintStore extends GenericStore {


    constructor(private climatefootprintService: ClimatefootprintService) {
        super();
    }

    invalidateAll(): void {
                
                
    }




                public climatefootprintPost(climateFootprintPolicyData?: ClimateFootprintPolicyData) : Observable<ClimateFootprintResponse> {
                    return this.climatefootprintService.climatefootprintPost(climateFootprintPolicyData).pipe(
                        map(value => {
                            
                            
                                    this.throwOrHandleValidationError(ClimateFootprintResponseSchema, value);
                            return value;
                        })
                    );
                }




}