import { inject, Injectable } from '@angular/core';
import {
  ForsikringDetaljer,
  Forsikringsoversigt,
  LocScaleInformation,
  OekonomiDetaljer,
  OversigtForsikring,
  PensionsInfoOrdninger,
  SpecialCustomerRules,
  VariantDetail,
  OpenForBusiness,
  OpenForLanguage,
  TilpasForsikringstal,
  OnlineService,
  HelbredsSikringModul
} from '@pfa/gen';
import {
  InjuryClaimType,
  InsuranceClaimable,
  InsuranceKeyNumbers,
  Insurances,
  InsuranceTag,
  InsuranceUnit,
  COMPANY_PFA,
  Coverage,
  OnlineServicesData
} from '@pfa/models';
import {
  ContentService,
  ContentUtilService,
  PageTrackingService
} from '@pfa/core';
import { CoverageDetail, CoverageDetailItem } from '@pfaform';

@Injectable({
  providedIn: 'root'
})
export class InsuranceUtilService {
  public static HEALTH_VARIATION = 5;
  public static LIFE_VARIATION = 50;
  public static CI_VARIATION = 40_000;

  private readonly contentUtilService: ContentUtilService =
    inject(ContentUtilService);
  private readonly contentService: ContentService = inject(ContentService);
  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);

  static taxFree(oversigtForsikring: OversigtForsikring): boolean {
    return oversigtForsikring.udbetalingBeskatning51;
  }

  static allTaxFree(insuranceOverview: OversigtForsikring[]): boolean {
    return insuranceOverview
      .filter(insurance => insurance.daekningsType !== 'INDBETALINGSSIKRING')
      .every(insurance => InsuranceUtilService.taxFree(insurance));
  }

  static allNonTaxFree(insuranceOverview: OversigtForsikring[]): boolean {
    return insuranceOverview
      .filter(insurance => insurance.daekningsType !== 'INDBETALINGSSIKRING')
      .every(insurance => !InsuranceUtilService.taxFree(insurance));
  }

  convertPensionInfo(
    pensionInfo: PensionsInfoOrdninger
  ): PensionsInfoOrdninger {
    pensionInfo.forsikring.helbredsTalBrutto = pensionInfo.forsikring
      .helbredsTalBrutto
      ? pensionInfo.forsikring.helbredsTalBrutto
      : 0;
    pensionInfo.forsikring.ialtTAE = pensionInfo.forsikring.ialtTAE
      ? pensionInfo.forsikring.ialtTAE
      : 0;
    pensionInfo.forsikring.livsTal = pensionInfo.forsikring.livsTal
      ? pensionInfo.forsikring.livsTal
      : 0;
    pensionInfo.forsikring.ialtLivsforsikring = pensionInfo.forsikring
      .ialtLivsforsikring
      ? pensionInfo.forsikring.ialtLivsforsikring
      : 0;
    pensionInfo.forsikring.pfaAnbefalerLivs.procent = pensionInfo.forsikring
      .pfaAnbefalerLivs.procent
      ? pensionInfo.forsikring.pfaAnbefalerLivs.procent
      : 0;
    pensionInfo.forsikring.pfaAnbefalerHelbred.procent = pensionInfo.forsikring
      .pfaAnbefalerHelbred.procent
      ? pensionInfo.forsikring.pfaAnbefalerHelbred.procent
      : 0;

    return pensionInfo;
  }

  mapInsurances(
    insuranceOverview: Forsikringsoversigt,
    insuranceDetails: ForsikringDetaljer,
    type_1: boolean
  ): Insurances {
    const insurances = new Insurances();
    const pfaHealth =
      insuranceOverview.pfaHelbredsForsikring.sundhedsForsikringer.length > 0 ||
      insuranceOverview.pfaHelbredsForsikringModuler.length > 0;

    insurances.preventiveCare = insuranceOverview.harForebygger;
    insurances.healthHotline = !type_1;
    insurances.onlineDoctor = insuranceOverview.hasOnlineDoctor;
    insurances.mentalWellbeing = insurances.abuseHotline = pfaHealth && type_1;
    insurances.pfaHealth = pfaHealth;
    insurances.diagnosis = insuranceOverview.harDiagnose;
    insurances.health =
      insuranceOverview.sundhedsForsikring.sundhedsForsikringer.length > 0 &&
      this.checkForPfa(
        insuranceOverview.sundhedsForsikring.sundhedsForsikringer
      );
    insurances.earlyCare =
      insuranceOverview.harEarlycare ||
      insuranceOverview.letpension?.harEarlycare;
    insurances.criticalIllnessPfa =
      insuranceOverview.kritisksygdomForsikring.kritisksygdomForsikringer
        .length > 0;
    insurances.lossOfOccupationalCapacityPfa =
      insuranceOverview.erhvervsevneForsikring.erhvervsevneForsikringer.some(
        insurance => insurance.beloeb > 0 && insurance.daekningsId !== 945
      );
    insurances.life =
      insuranceOverview.livsForsikring.livsForsikringer.length > 0;
    insurances.crossLife =
      insuranceOverview.krydslivsForsikring.daekningIkkeUnderUdbetaling &&
      insuranceOverview.krydslivsForsikring.livsForsikringer.length > 0;
    insurances.lifeForChildren =
      insuranceDetails?.tilpasForsikringstal?.daekningAendringBoernepension
        ?.skalVises;

    if (insuranceOverview.letpension) {
      insurances.criticalIllnessLetpension =
        insuranceOverview.letpension.kritisksygdomForsikring
          ?.kritisksygdomForsikringer?.length > 0;
      insurances.lossOfOccupationalCapacityLetpension =
        insuranceOverview.letpension.erhvervsevneForsikring?.erhvervsevneForsikringer?.some(
          insurance => insurance.beloeb > 0
        );
      insurances.pfaHealthLetpension =
        insuranceOverview.letpension.pfaHelbredsForsikring?.sundhedsForsikringer
          ?.length > 0 ||
        insuranceOverview.letpension.pfaHelbredsForsikringModuler?.length > 0;
    }
    insurances.criticalIllnessOtherOnly =
      !insurances.criticalIllnessLetpension &&
      insuranceOverview.kritisksygdomForsikring.kritisksygdomForsikringer.every(
        insurance => insurance.selskab !== COMPANY_PFA
      );

    return insurances;
  }

  mapInsuranceKeyNumbers(
    insuranceOverview: Forsikringsoversigt,
    insuranceDetails: ForsikringDetaljer,
    pensionInfo: PensionsInfoOrdninger
  ): InsuranceKeyNumbers {
    const insuranceKeyNumbers = new InsuranceKeyNumbers();

    insuranceKeyNumbers.criticalIllnessAmount =
      this.criticalIllnessTotalAmount(insuranceOverview);
    insuranceKeyNumbers.lossOfOccupationalCapacityAmount =
      pensionInfo?.forsikring?.ialtTAE;
    const taxFree = InsuranceUtilService.allTaxFree(
      insuranceOverview.erhvervsevneForsikring?.erhvervsevneForsikringer
    );
    insuranceKeyNumbers.lossOfOccupationalCapacityPercent = taxFree
      ? pensionInfo?.forsikring?.helbredsTal
      : pensionInfo?.forsikring?.helbredsTalBrutto;

    insuranceKeyNumbers.lifeAmount =
      pensionInfo?.forsikring?.ialtLivsforsikring;
    insuranceKeyNumbers.lifePercent = pensionInfo?.forsikring?.livsTal;
    insuranceKeyNumbers.lifeForChildrenAmount =
      insuranceDetails?.tilpasForsikringstal?.daekningAendringBoernepension
        ?.enhedType === 'Procent'
        ? insuranceDetails?.tilpasForsikringstal?.daekningAendringBoernepension
            ?.bruttobeloeb
        : insuranceDetails?.tilpasForsikringstal?.daekningAendringBoernepension
            ?.daekningsBeloeb;
    insuranceKeyNumbers.lifeForChildrenAge = 0;
    const primaryPolicyNumber =
      insuranceDetails.tilpasForsikringstal?.policenummer;
    insuranceOverview.livsForsikring.livsForsikringer?.forEach(
      livsForsikring => {
        if (
          livsForsikring.aftaleNummer === primaryPolicyNumber &&
          (livsForsikring.daekningsType === 'BOERNEPENSION' ||
            livsForsikring.daekningsType === 'BOERNEPENSION_MED_RISIKOOPHOER')
        ) {
          insuranceKeyNumbers.lifeForChildrenAge =
            insuranceKeyNumbers.lifeForChildrenAge > 0
              ? insuranceKeyNumbers.lifeForChildrenAge
              : livsForsikring.boerneUdloebAlder;
        }
      }
    );

    return insuranceKeyNumbers;
  }

  externalPolicy(insurance: OversigtForsikring): boolean {
    return insurance.selskab !== COMPANY_PFA && insurance.fromPI;
  }

  mapInsuranceClaimable(
    insurances: Insurances,
    insuranceOverview: Forsikringsoversigt
  ): InsuranceClaimable {
    const insuranceClaimable = new InsuranceClaimable();

    this.updateInsuranceClaimableLossOfOccupationalCapacity(
      insuranceClaimable,
      insurances,
      insuranceOverview
    );
    this.updateInsuranceClaimableCriticalIllness(
      insuranceClaimable,
      insurances,
      insuranceOverview
    );
    this.updateInsuranceClaimablePfahealth(
      insuranceClaimable,
      insurances,
      insuranceOverview
    );

    return insuranceClaimable;
  }

  private updateInsuranceClaimableLossOfOccupationalCapacity(
    insuranceClaimable: InsuranceClaimable,
    insurances: Insurances,
    insuranceOverview: Forsikringsoversigt
  ): void {
    if (
      insurances.lossOfOccupationalCapacityPfa &&
      this.checkForPfa(
        insuranceOverview.erhvervsevneForsikring?.erhvervsevneForsikringer,
        true
      )
    ) {
      insuranceClaimable.lossOfOccupationalCapacity = true;
    }
    if (
      insurances.lossOfOccupationalCapacityLetpension &&
      this.checkForPfa(
        insuranceOverview.letpension?.erhvervsevneForsikring
          ?.erhvervsevneForsikringer,
        true
      )
    ) {
      insuranceClaimable.lossOfOccupationalCapacity = true;
    }
  }

  private updateInsuranceClaimableCriticalIllness(
    insuranceClaimable: InsuranceClaimable,
    insurances: Insurances,
    insuranceOverview: Forsikringsoversigt
  ): void {
    if (
      insurances.criticalIllnessPfa &&
      this.checkForPfa(
        insuranceOverview.kritisksygdomForsikring?.kritisksygdomForsikringer
      )
    ) {
      insuranceClaimable.criticalIllness = true;
    }
    if (
      insurances.criticalIllnessLetpension &&
      this.checkForPfa(
        insuranceOverview.letpension?.kritisksygdomForsikring
          ?.kritisksygdomForsikringer
      )
    ) {
      insuranceClaimable.criticalIllness = true;
    }
  }

  private updateInsuranceClaimablePfahealth(
    insuranceClaimable: InsuranceClaimable,
    insurances: Insurances,
    insuranceOverview: Forsikringsoversigt
  ): void {
    if (insurances.pfaHealth) {
      if (
        this.checkForPfa(
          insuranceOverview.pfaHelbredsForsikring?.sundhedsForsikringer
        )
      ) {
        insuranceClaimable.pfaHealth = true;
      }
      insuranceOverview.pfaHelbredsForsikringModuler?.forEach(modul => {
        if (this.checkForPfa(modul.helbredsDaekninger)) {
          insuranceClaimable.pfaHealth = true;
        }
      });
    }
    if (insurances.pfaHealthLetpension) {
      if (
        this.checkForPfa(
          insuranceOverview.letpension?.pfaHelbredsForsikring
            ?.sundhedsForsikringer
        )
      ) {
        insuranceClaimable.pfaHealth = true;
      }
      insuranceOverview.letpension?.pfaHelbredsForsikringModuler?.forEach(
        modul => {
          if (this.checkForPfa(modul.helbredsDaekninger)) {
            insuranceClaimable.pfaHealth = true;
          }
        }
      );
    }
  }

  getInjuryClaimName(type: InjuryClaimType): string {
    let injuryClaimName = '';
    switch (type) {
      case InjuryClaimType.CRITICAL_ILLNESS:
        injuryClaimName = 'kritisksygdom';
        break;
      case InjuryClaimType.LOSS_OF_OCCUPATIONAL_CAPACITY:
        injuryClaimName = 'erhvervsevneforsikring';
        break;
      case InjuryClaimType.HEALTH_INSURANCE:
        injuryClaimName = 'pfahelbredssikring';
        break;
    }
    return injuryClaimName;
  }

  mapHealthNoteText(
    specialCustomerRules: SpecialCustomerRules,
    insurances: Insurances
  ): string {
    let healthHeaderNote = specialCustomerRules.type_1
      ? this.contentUtilService.getContent('DL.FG02.C19')
      : this.contentUtilService.getContent('DL.FG02.C07');
    if (insurances.pfaHealthLetpension) {
      if (insurances.pfaHealth) {
        healthHeaderNote +=
          ' / ' + this.contentUtilService.getContent('DL.FG02.C63');
      } else {
        healthHeaderNote = this.contentUtilService.getContent('DL.FG02.C63');
      }
    }

    return healthHeaderNote;
  }

  mapCriticalIllnessHeaderNoteText(insurances: Insurances): string {
    let criticalIllnessHeaderNote =
      this.contentUtilService.getContent('DL.FG02.C36');
    if (insurances.criticalIllnessLetpension) {
      if (insurances.criticalIllnessPfa) {
        criticalIllnessHeaderNote +=
          ' / ' + this.contentUtilService.getContent('DL.FG02.C51');
      } else {
        criticalIllnessHeaderNote =
          this.contentUtilService.getContent('DL.FG02.C51');
      }
    }

    return criticalIllnessHeaderNote;
  }

  mapLossOfOccupationalCapacityHeaderNoteText(insurances: Insurances): string {
    let lossOfOccupationalCapacityHeaderNote =
      this.contentUtilService.getContent('DL.FG02.C39');
    if (insurances.lossOfOccupationalCapacityLetpension) {
      if (insurances.lossOfOccupationalCapacityPfa) {
        lossOfOccupationalCapacityHeaderNote +=
          ' / ' + this.contentUtilService.getContent('DL.FG02.C50');
      } else {
        lossOfOccupationalCapacityHeaderNote =
          this.contentUtilService.getContent('DL.FG02.C50');
      }
    }

    return lossOfOccupationalCapacityHeaderNote;
  }

  getCurrentMultiplier(pensionInfo: PensionsInfoOrdninger): number {
    return pensionInfo.forsikring.livsTal / 100;
  }

  getRecommendedMultiplier(insuranceDetails: ForsikringDetaljer): number {
    return insuranceDetails.pfaAnbefalerLivs.procent / 100;
  }

  getSalary(financialSituation: OekonomiDetaljer): number {
    financialSituation.beregneAndenLoen = financialSituation.beregneAndenLoen
      ? financialSituation.beregneAndenLoen
      : 0;
    financialSituation.registreretLoen = financialSituation.registreretLoen
      ? financialSituation.registreretLoen
      : 0;

    return financialSituation.beregneAndenLoen
      ? financialSituation.beregneAndenLoen
      : financialSituation.registreretLoen;
  }

  isOpenForBusiness(
    type: InjuryClaimType,
    openForLanguage: OpenForLanguage
  ): boolean {
    const openForBusiness: OpenForBusiness =
      openForLanguage[this.contentService.getLanguage().toLocaleLowerCase()];
    return openForBusiness[type];
  }

  isOpenForBusinessInAnyLanguage(
    type: InjuryClaimType,
    openForLanguage: OpenForLanguage
  ): boolean {
    const openForBusinessDa: OpenForBusiness = openForLanguage['da'];
    const openForBusinessEn: OpenForBusiness = openForLanguage['en'];
    return openForBusinessDa[type] || openForBusinessEn[type];
  }

  payoutInProgressForSomePolicies(
    insuranceOverview: Forsikringsoversigt
  ): boolean {
    const payouts =
      insuranceOverview.forsikringDaekningerUnderUdbetalingGruppe.map(
        insurance => insurance.pensionsAftaleId
      );
    return insuranceOverview.erhvervsevneForsikring?.erhvervsevneForsikringer
      .map(overView => parseInt(overView.aftaleNummer, 10))
      .some(policyNumber => payouts.includes(policyNumber));
  }

  productVariantCoverages(
    variantDetails: VariantDetail[],
    insurances: OversigtForsikring[]
  ): Coverage[] {
    const variantIds = insurances.map(insurance => insurance.variantId);
    const language = this.contentService.getLanguage();
    return variantDetails
      .filter(
        variantDetail =>
          variantDetail.language.toLocaleLowerCase() === language &&
          variantIds.includes(variantDetail.variantId.value)
      )
      .map(variantDetail => ({
        headerText: variantDetail.name,
        details: variantDetail.details,
        quickActionLink: this.getQuickActionLink(variantDetail)
      }));
  }

  private getQuickActionLink(variantDetail: VariantDetail): string {
    if (
      variantDetail.onlineService &&
      variantDetail.onlineService !== 'UNKNOWN'
    ) {
      return this.getOnlineServiceLink(variantDetail.onlineService);
    }
    return '';
  }

  private getOnlineServiceLink(onlineService: OnlineService): string {
    switch (onlineService) {
      case 'COACH':
        return '/mineforsikringer/onlinesundhed/onlinecoach';
      case 'DOCTOR':
        return '/mineforsikringer/onlinesundhed/onlinelaege';
      case 'FAMILYTHERAPIST':
        return '/mineforsikringer/onlinesundhed/onlinefamilieraadgivning';
      case 'PHYSIOTHERAPIST':
        return '/mineforsikringer/onlinesundhed/onlinefysioterapeut';
      case 'PSYCHOLOGIST':
        return '/mineforsikringer/onlinesundhed/onlinepsykolog';
    }
    return '';
  }

  private checkForPfa(
    oversigtForsikring: OversigtForsikring[],
    amountPositiveCheck = false
  ): boolean {
    return oversigtForsikring?.some(
      insurance =>
        insurance.selskab === COMPANY_PFA &&
        (!amountPositiveCheck || insurance.beloeb > 0)
    );
  }

  formOfPayment(insuranceOverview: OversigtForsikring): string {
    const substitutionsMap: [string[], any][] = [
      [['KRITISKSYGDOM'], [insuranceOverview.risikoOphoerAlder]],
      [
        ['KRITISKSYGDOM_BOERN', 'KRITISKSYGDOM_BOERN_PI'],
        [
          insuranceOverview.betingelseParameter,
          insuranceOverview.boerneUdloebAlder
        ]
      ],
      [['KRITISKSYGDOM_IKKE_GODKENDT'], [0]]
    ];
    const substitution = substitutionsMap.find(coverageList =>
      coverageList[0].includes(insuranceOverview.daekningsType)
    ) ?? [{}, [0]];
    const substitutionValue =
      substitution[1].find((sub: number) => sub > 0) ?? '?';
    return this.contentUtilService.getContent(
      insuranceOverview.forsikringsContentId,
      substitutionValue
    );
  }

  criticalIllnessTotalAmount(
    insuranceOverview: Forsikringsoversigt,
    pfaCoveragesOnly = false
  ): number | undefined {
    return this.getCoverageDetailsSelf(insuranceOverview)
      .coverages.filter(
        coverage => !pfaCoveragesOnly || coverage.company === COMPANY_PFA
      )
      .map(coverage => coverage.amount)
      .reduce((prev, curr) => (prev ?? 0) + (curr ?? 0), 0);
  }

  getCoverageDetailsSelf(
    insuranceOverview: Forsikringsoversigt
  ): CoverageDetail {
    const headers: string[] = ['DL.FG02.C144', 'DL.FG02.C145', 'DL.FG02.C155'];
    const coverageDetailItems = this.fillCoverageDetailsSelf(insuranceOverview);
    return {
      headers,
      coverages: coverageDetailItems,
      header: 'DL.FG02.C154',
      headerNote: 'DL.FG02.C148',
      footer: null,
      subHeader: null
    } as CoverageDetail;
  }

  private fillCoverageDetailsSelf(
    insuranceOverview: Forsikringsoversigt
  ): CoverageDetailItem[] {
    const types = ['KRITISKSYGDOM', 'KRITISKSYGDOM_IKKE_GODKENDT'];
    return insuranceOverview.kritisksygdomForsikring?.kritisksygdomForsikringer
      .filter(insurance => types.includes(insurance.daekningsType))
      .map(
        insurance =>
          ({
            company: insurance.selskab,
            policyNote: this.contentUtilService.getContent(
              'DL.FG02.C150',
              insurance.aftaleNummer
            ),
            formOfPayment: this.formOfPayment(insurance),
            formOfPaymentNote: undefined,
            amount: insurance.beloeb,
            unit: InsuranceUnit.KR,
            amountNote: InsuranceUtilService.taxFree(insurance)
              ? 'DL.FG02.C153'
              : 'DL.FG02.C147',
            emphasize: true
          }) as CoverageDetailItem
      );
  }

  scaleTags(insuranceDetails: ForsikringDetaljer): InsuranceTag[] {
    const autoTAE =
      insuranceDetails.originalTilpasForsikringstal.autoAdjustment?.type ===
      'TAE';
    const tags = new Array<InsuranceTag>();
    if (autoTAE) {
      const hasScale100 =
        insuranceDetails.locScaleInformation?.current === 'LOC_100';
      const hasScale80 =
        insuranceDetails.locScaleInformation?.current === 'LOC_80';
      const scale80allowed =
        insuranceDetails.locScaleInformation?.allowed.includes('LOC_80');
      if (hasScale80) {
        tags.push({
          text: 'DL.FG02.C342',
          tooltip: this.getLocScaleTooltip(hasScale100)
        });
      } else if (hasScale100) {
        if (scale80allowed) {
          tags.push({
            text: 'DL.FG02.C351',
            tooltip: this.getLocScaleTooltip(hasScale100)
          });
        } else {
          tags.push({
            text: 'DL.FG02.C341',
            tooltip: this.getLocScaleTooltip(hasScale100)
          });
        }
      }
    }
    return tags;
  }

  getLocScaleTag(locScaleInformation: LocScaleInformation): string {
    const scale80allowed = locScaleInformation.allowed.includes('LOC_80');
    const hasScale80 = locScaleInformation.current === 'LOC_80';
    const hasScale100 = locScaleInformation.current === 'LOC_100';
    return this.getLocScaleTagText(hasScale80, hasScale100, scale80allowed);
  }

  getLocScaleTagText(
    hasScale80: boolean,
    hasScale100: boolean,
    scale80allowed: boolean
  ): string {
    let text = hasScale80 ? 'DL.FG02.C342' : '';
    if (hasScale100) {
      text = scale80allowed ? 'DL.FG02.C351' : 'DL.FG02.C341';
    }
    return text;
  }

  getLocScaleTooltip(hasScale100: boolean): string {
    const tooltipSubst = hasScale100 ? '100' : '80';
    return this.contentUtilService.getContent('DL.FG02.C352', [tooltipSubst]);
  }

  isPolicyBlocked(tilpasForsikringstal: TilpasForsikringstal): boolean {
    const blockedStates = ['Fripolice', 'Henstand', 'Udbetalingspolicedoed'];
    return (
      tilpasForsikringstal.udenOpsparing ||
      blockedStates.includes(tilpasForsikringstal.tilstand)
    );
  }

  payoutsInProgress(insuranceOverview: Forsikringsoversigt): boolean {
    return (
      insuranceOverview.forsikringDaekningerUnderUdbetalingGruppe?.length > 0
    );
  }

  getOnlineServicesData(onlineService: OnlineService): OnlineServicesData {
    const urlHejdoktor = this.contentUtilService.getContent('DL.FG02.C436');
    const urlPresciba = this.contentUtilService.getContent('DL.FG02.C437');
    const nameHejdoktor = this.contentUtilService.getContent('DL.FG02.C438');
    const namePresciba = this.contentUtilService.getContent('DL.FG02.C439');
    switch (onlineService) {
      case 'COACH':
        return {
          serviceName: this.contentUtilService.getContent('DL.FG02.C383'),
          providerName: namePresciba,
          detailsUrl: 'mineforsikringer/onlinesundhed/onlinecoach',
          externalUrl: urlPresciba,
          imageUrl: '/assets/images/online_coach.svg',
          openingHoursTagText:
            this.contentUtilService.getContent('DL.FG02.C451'),
          trackingNavigationName: 'onlinecoach',
          trackingNavigationAction: 'go to onlinecoach'
        };
      case 'DOCTOR':
        return {
          serviceName: this.contentUtilService.getContent('DL.FG02.C356'),
          providerName: nameHejdoktor,
          detailsUrl: 'mineforsikringer/onlinesundhed/onlinelaege',
          externalUrl: '',
          imageUrl: '/assets/images/online_doctor.svg',
          openingHoursTagText:
            this.contentUtilService.getContent('DL.FG02.C448'),
          trackingNavigationName: 'onlinedoctor',
          trackingNavigationAction: 'go to onlinedoctor'
        };
      case 'FAMILYTHERAPIST':
        return {
          serviceName: this.contentUtilService.getContent('DL.FG02.C381'),
          providerName: namePresciba,
          detailsUrl: 'mineforsikringer/onlinesundhed/onlinefamilieraadgivning',
          externalUrl: urlPresciba,
          imageUrl: '/assets/images/online_familytherapist.svg',
          openingHoursTagText:
            this.contentUtilService.getContent('DL.FG02.C452'),
          trackingNavigationName: 'onlinefamilytherapist',
          trackingNavigationAction: 'go to onlinefamilytherapist'
        };
      case 'PHYSIOTHERAPIST':
        return {
          serviceName: this.contentUtilService.getContent('DL.FG02.C382'),
          providerName: nameHejdoktor,
          detailsUrl: 'mineforsikringer/onlinesundhed/onlinefysioterapeut',
          externalUrl: urlHejdoktor,
          imageUrl: '/assets/images/online_physiotherapist.svg',
          openingHoursTagText:
            this.contentUtilService.getContent('DL.FG02.C449'),
          trackingNavigationName: 'onlinephysiontherapist',
          trackingNavigationAction: 'go to onlinephysiontherapist'
        };
      case 'PSYCHOLOGIST':
        return {
          serviceName: this.contentUtilService.getContent('DL.FG02.C380'),
          providerName: namePresciba,
          detailsUrl: 'mineforsikringer/onlinesundhed/onlinepsykolog',
          externalUrl: urlPresciba,
          imageUrl: '/assets/images/online_psychologist.svg',
          openingHoursTagText:
            this.contentUtilService.getContent('DL.FG02.C450'),
          trackingNavigationName: 'onlinepsychologist',
          trackingNavigationAction: 'go to onlinepsychologist'
        };
    }

    return {} as OnlineServicesData;
  }

  public getHealthCoverageIds(
    insuranceOverview: Forsikringsoversigt
  ): Array<number> {
    const ids: Array<number> = new Array<number>();
    insuranceOverview.pfaHelbredsForsikringModuler.forEach(module => {
      module?.helbredsDaekninger.forEach(coverage =>
        ids.push(coverage.daekningsId)
      );
      module?.helbredsDaekningerBoern.forEach(coverage =>
        ids.push(coverage.daekningsId)
      );
    });
    return ids;
  }

  getHealthVariantIds(
    insuranceOverview: Forsikringsoversigt,
    insurances: Insurances
  ): Array<string> {
    let healthVariantIds: Array<string> = new Array<string>();

    if (insurances.pfaHealth) {
      const allVariantsIds = this.allVariantsIds(
        insuranceOverview.pfaHelbredsForsikringModuler
      );
      if (allVariantsIds && allVariantsIds.length > 0) {
        healthVariantIds = allVariantsIds.filter(
          (variandId, index) => allVariantsIds.indexOf(variandId) === index
        );
      }
    }

    return healthVariantIds;
  }

  getCriticalIllnessVariantIds(
    insuranceOverview: Forsikringsoversigt,
    insurances: Insurances
  ): Array<string> {
    let criticalIllnessVarianIds: Array<string> = new Array<string>();

    if (insurances.criticalIllnessPfa) {
      criticalIllnessVarianIds = this.filterVariantsIdsByDefinedAndNotZero(
        insuranceOverview.kritisksygdomForsikring.kritisksygdomForsikringer
      );
    }

    return criticalIllnessVarianIds;
  }

  private allVariantsIds(modules: HelbredsSikringModul[]): string[] {
    const allVariantIds: string[] = [];
    modules?.forEach(module => {
      const coverages = module.helbredsDaekninger.concat(
        module.helbredsDaekningerBoern
      );
      const variantIds = coverages
        .map(coverage => coverage.variantId)
        .filter(variantId => variantId && variantId !== '0');
      allVariantIds.push(...variantIds);
    });
    return allVariantIds;
  }

  private filterVariantsIdsByDefinedAndNotZero(
    criticalIllnessInsurancesCoverages: OversigtForsikring[]
  ): string[] {
    const variantIdsDefinedAndNotZero: string[] = [];
    const variantIds = criticalIllnessInsurancesCoverages
      .map(coverage => coverage.variantId)
      .filter(variantId => variantId && variantId !== '0');
    variantIdsDefinedAndNotZero.push(...variantIds);
    return variantIdsDefinedAndNotZero;
  }

  getOnlineServiceName(onlineServiceType: OnlineService): string {
    switch (onlineServiceType) {
      case 'COACH':
        return this.contentUtilService.getContent('DL.FG02.C422');
      case 'DOCTOR':
        return this.contentUtilService.getContent('DL.FG02.C423');
      case 'FAMILYTHERAPIST':
        return this.contentUtilService.getContent('DL.FG02.C424');
      case 'PHYSIOTHERAPIST':
        return this.contentUtilService.getContent('DL.FG02.C425');
      case 'PSYCHOLOGIST':
        return this.contentUtilService.getContent('DL.FG02.C426');
    }
    return '';
  }

  getOnlineServiceNoteText(onlineServices: Array<OnlineService>): string {
    let onlineServicesNote = '';
    const onlineServicesSorted = this.sortOnlineServices(onlineServices);
    onlineServicesSorted.forEach(
      (onlineServiceType: OnlineService, index: number) => {
        onlineServicesNote += `${this.getOnlineServiceName(onlineServiceType)}`;
        if (index === onlineServicesSorted.length - 2) {
          onlineServicesNote +=
            this.contentUtilService.getContent('DL.FG02.C421');
        } else if (index < onlineServicesSorted.length - 1) {
          onlineServicesNote += ', ';
        }
      }
    );
    return onlineServicesNote;
  }

  sortOnlineServices(
    onlineServices: Array<OnlineService>
  ): Array<OnlineService> {
    const onlineServicesSorted = new Array<OnlineService>();
    if (onlineServices.includes(OnlineService.Doctor)) {
      onlineServicesSorted.push(OnlineService.Doctor);
    }
    if (onlineServices.includes(OnlineService.Physiotherapist)) {
      onlineServicesSorted.push(OnlineService.Physiotherapist);
    }
    if (onlineServices.includes(OnlineService.Psychologist)) {
      onlineServicesSorted.push(OnlineService.Psychologist);
    }
    if (onlineServices.includes(OnlineService.Familytherapist)) {
      onlineServicesSorted.push(OnlineService.Familytherapist);
    }
    if (onlineServices.includes(OnlineService.Coach)) {
      onlineServicesSorted.push(OnlineService.Coach);
    }
    return onlineServicesSorted;
  }

  getOnlineVariants(
    variantDetails: Array<VariantDetail>
  ): Array<OnlineService> {
    const onlineServices = new Array<OnlineService>();
    variantDetails?.forEach(variantDetail => {
      if (
        variantDetail?.onlineService !== null &&
        variantDetail?.onlineService !== undefined &&
        variantDetail?.onlineService !== OnlineService.Unknown
      ) {
        if (!onlineServices.includes(variantDetail.onlineService)) {
          onlineServices.push(variantDetail.onlineService);
        }
      }
    });
    return onlineServices;
  }

  hasOnlineServiceDoctorOnly(onlineServices: Array<OnlineService>): boolean {
    return !onlineServices.some(onlineService => onlineService !== 'DOCTOR');
  }

  bookOnlineService(
    onlineServiceType: OnlineService,
    onlineDoctorUrl: string,
    data: OnlineServicesData
  ): void {
    let url: string, trackingUrl: string;
    if (onlineServiceType === 'DOCTOR') {
      url = onlineDoctorUrl;
      trackingUrl = url.slice(0, url.lastIndexOf('/'));
    } else {
      url = trackingUrl = data.externalUrl;
    }
    this.pageTrackingService.trackNavigation(
      data.trackingNavigationName,
      data.trackingNavigationAction,
      trackingUrl
    );
    window.open(url, '_blank');
  }

  showChildInsurance(insuranceOverview: Forsikringsoversigt): boolean {
    return insuranceOverview.pfaHelbredsForsikringModuler?.some(
      healthinsuranceModule =>
        healthinsuranceModule.helbredsDaekningerBoern?.length > 0
    );
  }
}
