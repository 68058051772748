import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
} from '../model/models';

import { UploadskadesanmeldelsedocumentService } from './uploadskadesanmeldelsedocument.service';


@Injectable({
    providedIn: 'root'
})
export class UploadskadesanmeldelsedocumentStore extends GenericStore {


    constructor(private uploadskadesanmeldelsedocumentService: UploadskadesanmeldelsedocumentService) {
        super();
    }

    invalidateAll(): void {
                
                
                
                
                
                
    }




                public uploadSkadesanmeldelseHsDocumentPost(id: string,category: string,metadata: string,documents: Blob,charset?: string) : Observable<any> {
                    return this.uploadskadesanmeldelsedocumentService.uploadSkadesanmeldelseHsDocumentPost(id,category,metadata,documents,charset).pipe(
                        map(value => {
                            
                            
                            return value;
                        })
                    );
                }






                public uploadSkadesanmeldelseKsDocumentPost(id: string,category: string,metadata: string,documents: Blob,charset?: string) : Observable<any> {
                    return this.uploadskadesanmeldelsedocumentService.uploadSkadesanmeldelseKsDocumentPost(id,category,metadata,documents,charset).pipe(
                        map(value => {
                            
                            
                            return value;
                        })
                    );
                }






                public uploadSkadesanmeldelseTaeDocumentPost(id: string,category: string,metadata: string,documents: Blob,charset?: string) : Observable<any> {
                    return this.uploadskadesanmeldelsedocumentService.uploadSkadesanmeldelseTaeDocumentPost(id,category,metadata,documents,charset).pipe(
                        map(value => {
                            
                            
                            return value;
                        })
                    );
                }




}