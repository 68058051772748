import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        BrugerundersoegelseAdmin,
        BrugerundersoegelseAdminSchema,
        KillswitchDetails,
        KillswitchDetailsSchema,
        KillswitchesAdminResponse,
        KillswitchesAdminResponseSchema,
        LocPayScaleDateResponse,
        LocPayScaleDateResponseSchema,
        LocPayScaleResponse,
        LocPayScaleResponseSchema,
        RateLimit,
        RateLimitSchema,
        SuccessResponse,
        SuccessResponseSchema
} from '../model/models';

import { SystemadministrationService } from './systemadministration.service';


@Injectable({
    providedIn: 'root'
})
export class SystemadministrationStore extends GenericStore {

            private systemadministrationlocpayscaleIndexCache: Map<string, RestCacheEntry<LocPayScaleDateResponse>> = new Map<string, RestCacheEntry<LocPayScaleDateResponse>>();
            private systemadministrationlocpayscalescaleactiveFromShowCache: Map<string, RestCacheEntry<LocPayScaleResponse>> = new Map<string, RestCacheEntry<LocPayScaleResponse>>();
            private systemadministrationratelimitIndexCache: Map<string, RestCacheEntry<RateLimit>> = new Map<string, RestCacheEntry<RateLimit>>();
            private systemadministrationhentkillswitchesadminIndexCache: Map<string, RestCacheEntry<KillswitchesAdminResponse>> = new Map<string, RestCacheEntry<KillswitchesAdminResponse>>();
            private systemadministrationhentbrugerundersoegelseadminIndexCache: Map<string, RestCacheEntry<BrugerundersoegelseAdmin>> = new Map<string, RestCacheEntry<BrugerundersoegelseAdmin>>();
            private systemadministrationhentlogningafdatacsvstartDatoslutDatoShowCache: Map<string, RestCacheEntry<Blob>> = new Map<string, RestCacheEntry<Blob>>();

    constructor(private systemadministrationService: SystemadministrationService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidatesystemadministrationhentbrugerundersoegelseadminIndex();
                
                this.invalidatesystemadministrationhentkillswitchesadminIndex();
                this.invalidatesystemadministrationhentlogningafdatacsvstartDatoslutDatoShow();
                
                
                this.invalidatesystemadministrationlocpayscaleIndex();
                
                
                this.invalidatesystemadministrationlocpayscalescaleactiveFromShow();
                
                
                
                
                
                
                this.invalidatesystemadministrationratelimitIndex();
                
                
    }


                public systemadministrationHentbrugerundersoegelseadminGet() : Observable<BrugerundersoegelseAdmin> {
                    let hash = "0";
                    if (this.systemadministrationhentbrugerundersoegelseadminIndexCache.has(hash)) {
                        return this.systemadministrationhentbrugerundersoegelseadminIndexCache.get(hash)!.observable;
                    } else {
                        let systemadministrationhentbrugerundersoegelseadminIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let systemadministrationhentbrugerundersoegelseadminIndex = this.initiateStream(systemadministrationhentbrugerundersoegelseadminIndexSubject, this.systemadministrationService.systemadministrationHentbrugerundersoegelseadminGet(), BrugerundersoegelseAdminSchema);
                        this.systemadministrationhentbrugerundersoegelseadminIndexCache.set(hash, new RestCacheEntry<BrugerundersoegelseAdmin>(systemadministrationhentbrugerundersoegelseadminIndex, systemadministrationhentbrugerundersoegelseadminIndexSubject));
                        return systemadministrationhentbrugerundersoegelseadminIndex;
                    }
                }

                public invalidatesystemadministrationhentbrugerundersoegelseadminIndex() {
                    this.systemadministrationhentbrugerundersoegelseadminIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






                public systemadministrationHentkillswitchesadminGet() : Observable<KillswitchesAdminResponse> {
                    let hash = "0";
                    if (this.systemadministrationhentkillswitchesadminIndexCache.has(hash)) {
                        return this.systemadministrationhentkillswitchesadminIndexCache.get(hash)!.observable;
                    } else {
                        let systemadministrationhentkillswitchesadminIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let systemadministrationhentkillswitchesadminIndex = this.initiateStream(systemadministrationhentkillswitchesadminIndexSubject, this.systemadministrationService.systemadministrationHentkillswitchesadminGet(), KillswitchesAdminResponseSchema);
                        this.systemadministrationhentkillswitchesadminIndexCache.set(hash, new RestCacheEntry<KillswitchesAdminResponse>(systemadministrationhentkillswitchesadminIndex, systemadministrationhentkillswitchesadminIndexSubject));
                        return systemadministrationhentkillswitchesadminIndex;
                    }
                }

                public invalidatesystemadministrationhentkillswitchesadminIndex() {
                    this.systemadministrationhentkillswitchesadminIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }





                public systemadministrationHentlogningafdatacsvStartDatoSlutDatoGet(startDato: string,slutDato: string) : Observable<Blob> {
                    let hash = this.hashKey((startDato.toString() || "0") + (slutDato.toString() || "0"));
                    if (this.systemadministrationhentlogningafdatacsvstartDatoslutDatoShowCache.has(hash)) {
                        return this.systemadministrationhentlogningafdatacsvstartDatoslutDatoShowCache.get(hash)!.observable;
                    } else {
                        let systemadministrationhentlogningafdatacsvstartDatoslutDatoShowSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let systemadministrationhentlogningafdatacsvstartDatoslutDatoShow = this.initiateStream(systemadministrationhentlogningafdatacsvstartDatoslutDatoShowSubject, this.systemadministrationService.systemadministrationHentlogningafdatacsvStartDatoSlutDatoGet(startDato,slutDato), JoiObj.any());
                        this.systemadministrationhentlogningafdatacsvstartDatoslutDatoShowCache.set(hash, new RestCacheEntry<Blob>(systemadministrationhentlogningafdatacsvstartDatoslutDatoShow, systemadministrationhentlogningafdatacsvstartDatoslutDatoShowSubject));
                        return systemadministrationhentlogningafdatacsvstartDatoslutDatoShow;
                    }
                }

                public invalidatesystemadministrationhentlogningafdatacsvstartDatoslutDatoShowById(startDato: string,slutDato: string) {
                    let hash = this.hashKey((startDato.toString() || "0") + (slutDato.toString() || "0"));
                    if (this.systemadministrationhentlogningafdatacsvstartDatoslutDatoShowCache.has(hash)) {
                        this.systemadministrationhentlogningafdatacsvstartDatoslutDatoShowCache.get(hash)!.subject.next(0);
                    }
                }

                public invalidatesystemadministrationhentlogningafdatacsvstartDatoslutDatoShow() {
                    this.systemadministrationhentlogningafdatacsvstartDatoslutDatoShowCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }







                public systemadministrationLocpayscaleGet() : Observable<LocPayScaleDateResponse> {
                    let hash = "0";
                    if (this.systemadministrationlocpayscaleIndexCache.has(hash)) {
                        return this.systemadministrationlocpayscaleIndexCache.get(hash)!.observable;
                    } else {
                        let systemadministrationlocpayscaleIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let systemadministrationlocpayscaleIndex = this.initiateStream(systemadministrationlocpayscaleIndexSubject, this.systemadministrationService.systemadministrationLocpayscaleGet(), LocPayScaleDateResponseSchema);
                        this.systemadministrationlocpayscaleIndexCache.set(hash, new RestCacheEntry<LocPayScaleDateResponse>(systemadministrationlocpayscaleIndex, systemadministrationlocpayscaleIndexSubject));
                        return systemadministrationlocpayscaleIndex;
                    }
                }

                public invalidatesystemadministrationlocpayscaleIndex() {
                    this.systemadministrationlocpayscaleIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }








                public systemadministrationLocpayscalePost(scale: string,activeFrom: string,file: Blob) : Observable<any> {
                    return this.systemadministrationService.systemadministrationLocpayscalePost(scale,activeFrom,file).pipe(
                        map(value => {
                            
                            this.invalidatesystemadministrationlocpayscaleIndex();
                            return value;
                        })
                    );
                }



                public systemadministrationLocpayscaleScaleActiveFromGet(scale: number,activeFrom: string) : Observable<LocPayScaleResponse> {
                    let hash = this.hashKey((scale.toString() || "0") + (activeFrom.toString() || "0"));
                    if (this.systemadministrationlocpayscalescaleactiveFromShowCache.has(hash)) {
                        return this.systemadministrationlocpayscalescaleactiveFromShowCache.get(hash)!.observable;
                    } else {
                        let systemadministrationlocpayscalescaleactiveFromShowSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let systemadministrationlocpayscalescaleactiveFromShow = this.initiateStream(systemadministrationlocpayscalescaleactiveFromShowSubject, this.systemadministrationService.systemadministrationLocpayscaleScaleActiveFromGet(scale,activeFrom), LocPayScaleResponseSchema);
                        this.systemadministrationlocpayscalescaleactiveFromShowCache.set(hash, new RestCacheEntry<LocPayScaleResponse>(systemadministrationlocpayscalescaleactiveFromShow, systemadministrationlocpayscalescaleactiveFromShowSubject));
                        return systemadministrationlocpayscalescaleactiveFromShow;
                    }
                }

                public invalidatesystemadministrationlocpayscalescaleactiveFromShowById(scale: number,activeFrom: string) {
                    let hash = this.hashKey((scale.toString() || "0") + (activeFrom.toString() || "0"));
                    if (this.systemadministrationlocpayscalescaleactiveFromShowCache.has(hash)) {
                        this.systemadministrationlocpayscalescaleactiveFromShowCache.get(hash)!.subject.next(0);
                    }
                }

                public invalidatesystemadministrationlocpayscalescaleactiveFromShow() {
                    this.systemadministrationlocpayscalescaleactiveFromShowCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }









                public systemadministrationOpdaterbrugerundersoegelseadminPost(brugerundersoegelseAdmin?: BrugerundersoegelseAdmin) : Observable<SuccessResponse> {
                    return this.systemadministrationService.systemadministrationOpdaterbrugerundersoegelseadminPost(brugerundersoegelseAdmin).pipe(
                        map(value => {
                            
                            
                                    this.throwOrHandleValidationError(SuccessResponseSchema, value);
                            return value;
                        })
                    );
                }






                public systemadministrationOpdaterkillswitchesadminPost(killswitchDetails?: KillswitchDetails) : Observable<SuccessResponse> {
                    return this.systemadministrationService.systemadministrationOpdaterkillswitchesadminPost(killswitchDetails).pipe(
                        map(value => {
                            
                            
                                    this.throwOrHandleValidationError(SuccessResponseSchema, value);
                            return value;
                        })
                    );
                }




                public systemadministrationRatelimitGet() : Observable<RateLimit> {
                    let hash = "0";
                    if (this.systemadministrationratelimitIndexCache.has(hash)) {
                        return this.systemadministrationratelimitIndexCache.get(hash)!.observable;
                    } else {
                        let systemadministrationratelimitIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let systemadministrationratelimitIndex = this.initiateStream(systemadministrationratelimitIndexSubject, this.systemadministrationService.systemadministrationRatelimitGet(), RateLimitSchema);
                        this.systemadministrationratelimitIndexCache.set(hash, new RestCacheEntry<RateLimit>(systemadministrationratelimitIndex, systemadministrationratelimitIndexSubject));
                        return systemadministrationratelimitIndex;
                    }
                }

                public invalidatesystemadministrationratelimitIndex() {
                    this.systemadministrationratelimitIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }









                public systemadministrationRatelimitPut(rateLimit?: RateLimit) : Observable<any> {
                    return this.systemadministrationService.systemadministrationRatelimitPut(rateLimit).pipe(
                        map(value => {
                            
                            this.invalidatesystemadministrationratelimitIndex();
                            return value;
                        })
                    );
                }



}