import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        SkatStatSatser,
        SkatStatSatserSchema
} from '../model/models';

import { MicroappconfigurationService } from './microappconfiguration.service';


@Injectable({
    providedIn: 'root'
})
export class MicroappconfigurationStore extends GenericStore {

            private microappconfigurationIndexCache: Map<string, RestCacheEntry<SkatStatSatser>> = new Map<string, RestCacheEntry<SkatStatSatser>>();

    constructor(private microappconfigurationService: MicroappconfigurationService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidatemicroappconfigurationIndex();
    }


                public microappconfigurationGet(name: string,id: string,year: number) : Observable<SkatStatSatser> {
                    let hash = this.hashKey((name.toString() || "0") + (id.toString() || "0") + (year.toString() || "0"));
                    if (this.microappconfigurationIndexCache.has(hash)) {
                        return this.microappconfigurationIndexCache.get(hash)!.observable;
                    } else {
                        let microappconfigurationIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let microappconfigurationIndex = this.initiateStream(microappconfigurationIndexSubject, this.microappconfigurationService.microappconfigurationGet(name,id,year), SkatStatSatserSchema);
                        this.microappconfigurationIndexCache.set(hash, new RestCacheEntry<SkatStatSatser>(microappconfigurationIndex, microappconfigurationIndexSubject));
                        return microappconfigurationIndex;
                    }
                }

                public invalidatemicroappconfigurationIndex() {
                    this.microappconfigurationIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






}