import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        GodkendSvar,
        GodkendSvarSchema,
        HentInternOverfoerselRequest,
        HentInternOverfoerselRequestSchema,
        HentInternOverfoerselResponse,
        HentInternOverfoerselResponseSchema,
        InternOverfoerselSignereRequestObj,
        InternOverfoerselSignereRequestObjSchema,
        InternalTransferVisibilityResponse,
        InternalTransferVisibilityResponseSchema,
        SigningIframeResult,
        SigningIframeResultSchema,
        SuccessResponse,
        SuccessResponseSchema
} from '../model/models';

import { OverfoerselService } from './overfoersel.service';


@Injectable({
    providedIn: 'root'
})
export class OverfoerselStore extends GenericStore {

            private overfoerselcheckVisibilityIndexCache: Map<string, RestCacheEntry<InternalTransferVisibilityResponse>> = new Map<string, RestCacheEntry<InternalTransferVisibilityResponse>>();

    constructor(private overfoerselService: OverfoerselService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidateoverfoerselcheckVisibilityIndex();
                
                
                
                
                
                
                
                
                
                
    }


                public overfoerselCheckVisibilityGet() : Observable<InternalTransferVisibilityResponse> {
                    let hash = "0";
                    if (this.overfoerselcheckVisibilityIndexCache.has(hash)) {
                        return this.overfoerselcheckVisibilityIndexCache.get(hash)!.observable;
                    } else {
                        let overfoerselcheckVisibilityIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let overfoerselcheckVisibilityIndex = this.initiateStream(overfoerselcheckVisibilityIndexSubject, this.overfoerselService.overfoerselCheckVisibilityGet(), InternalTransferVisibilityResponseSchema);
                        this.overfoerselcheckVisibilityIndexCache.set(hash, new RestCacheEntry<InternalTransferVisibilityResponse>(overfoerselcheckVisibilityIndex, overfoerselcheckVisibilityIndexSubject));
                        return overfoerselcheckVisibilityIndex;
                    }
                }

                public invalidateoverfoerselcheckVisibilityIndex() {
                    this.overfoerselcheckVisibilityIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }








                public overfoerselConfirmChoicePost() : Observable<SuccessResponse> {
                    return this.overfoerselService.overfoerselConfirmChoicePost().pipe(
                        map(value => {
                            
                            
                                    this.throwOrHandleValidationError(SuccessResponseSchema, value);
                            return value;
                        })
                    );
                }






                public overfoerselGemfrigivelsebeskedPost() : Observable<SuccessResponse> {
                    return this.overfoerselService.overfoerselGemfrigivelsebeskedPost().pipe(
                        map(value => {
                            
                            
                                    this.throwOrHandleValidationError(SuccessResponseSchema, value);
                            return value;
                        })
                    );
                }






                public overfoerselHentinternoverfoerselPost(hentInternOverfoerselRequest?: HentInternOverfoerselRequest) : Observable<HentInternOverfoerselResponse> {
                    return this.overfoerselService.overfoerselHentinternoverfoerselPost(hentInternOverfoerselRequest).pipe(
                        map(value => {
                            
                            
                                    this.throwOrHandleValidationError(HentInternOverfoerselResponseSchema, value);
                            return value;
                        })
                    );
                }







                public overfoerselSign1Put(internOverfoerselSignereRequestObj?: InternOverfoerselSignereRequestObj) : Observable<GodkendSvar> {
                    return this.overfoerselService.overfoerselSign1Put(internOverfoerselSignereRequestObj).pipe(
                        map(value => {
                            
                            
                                    this.throwOrHandleValidationError(GodkendSvarSchema, value);
                            return value;
                        })
                    );
                }





                public overfoerselSignPost(internOverfoerselSignereRequestObj?: InternOverfoerselSignereRequestObj) : Observable<SigningIframeResult> {
                    return this.overfoerselService.overfoerselSignPost(internOverfoerselSignereRequestObj).pipe(
                        map(value => {
                            
                            
                                    this.throwOrHandleValidationError(SigningIframeResultSchema, value);
                            return value;
                        })
                    );
                }




}