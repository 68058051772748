import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        Signature,
        SignatureSchema,
        SignatureIframe,
        SignatureIframeSchema
} from '../model/models';

import { MobilesigningService } from './mobilesigning.service';


@Injectable({
    providedIn: 'root'
})
export class MobilesigningStore extends GenericStore {

            private mobilesigningsignaturesuuidShowCache: Map<string, RestCacheEntry<Signature>> = new Map<string, RestCacheEntry<Signature>>();
            private mobilesigningiframesuuidShowCache: Map<string, RestCacheEntry<SignatureIframe>> = new Map<string, RestCacheEntry<SignatureIframe>>();

    constructor(private mobilesigningService: MobilesigningService) {
        super();
    }

    invalidateAll(): void {
                this.invalidatemobilesigningiframesuuidShow();
                
                this.invalidatemobilesigningsignaturesuuidShow();
                
                
                
    }

                public mobilesigningIframesUuidGet(uuid: string) : Observable<SignatureIframe> {
                    let hash = this.hashKey((uuid.toString() || "0"));
                    if (this.mobilesigningiframesuuidShowCache.has(hash)) {
                        return this.mobilesigningiframesuuidShowCache.get(hash)!.observable;
                    } else {
                        let mobilesigningiframesuuidShowSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let mobilesigningiframesuuidShow = this.initiateStream(mobilesigningiframesuuidShowSubject, this.mobilesigningService.mobilesigningIframesUuidGet(uuid), SignatureIframeSchema);
                        this.mobilesigningiframesuuidShowCache.set(hash, new RestCacheEntry<SignatureIframe>(mobilesigningiframesuuidShow, mobilesigningiframesuuidShowSubject));
                        return mobilesigningiframesuuidShow;
                    }
                }

                public invalidatemobilesigningiframesuuidShowById(uuid: string) {
                    let hash = this.hashKey((uuid.toString() || "0"));
                    if (this.mobilesigningiframesuuidShowCache.has(hash)) {
                        this.mobilesigningiframesuuidShowCache.get(hash)!.subject.next(0);
                    }
                }

                public invalidatemobilesigningiframesuuidShow() {
                    this.mobilesigningiframesuuidShowCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






                public mobilesigningSignaturesUuidGet(uuid: string) : Observable<Signature> {
                    let hash = this.hashKey((uuid.toString() || "0"));
                    if (this.mobilesigningsignaturesuuidShowCache.has(hash)) {
                        return this.mobilesigningsignaturesuuidShowCache.get(hash)!.observable;
                    } else {
                        let mobilesigningsignaturesuuidShowSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let mobilesigningsignaturesuuidShow = this.initiateStream(mobilesigningsignaturesuuidShowSubject, this.mobilesigningService.mobilesigningSignaturesUuidGet(uuid), SignatureSchema);
                        this.mobilesigningsignaturesuuidShowCache.set(hash, new RestCacheEntry<Signature>(mobilesigningsignaturesuuidShow, mobilesigningsignaturesuuidShowSubject));
                        return mobilesigningsignaturesuuidShow;
                    }
                }

                public invalidatemobilesigningsignaturesuuidShowById(uuid: string) {
                    let hash = this.hashKey((uuid.toString() || "0"));
                    if (this.mobilesigningsignaturesuuidShowCache.has(hash)) {
                        this.mobilesigningsignaturesuuidShowCache.get(hash)!.subject.next(0);
                    }
                }

                public invalidatemobilesigningsignaturesuuidShow() {
                    this.mobilesigningsignaturesuuidShowCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }










                public mobilesigningSignaturesUuidPut(uuid: string,signature: Signature) : Observable<any> {
                    return this.mobilesigningService.mobilesigningSignaturesUuidPut(uuid,signature).pipe(
                        map(value => {
                            this.invalidatemobilesigningsignaturesuuidShow();
                            
                            return value;
                        })
                    );
                }



}