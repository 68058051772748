import { Injectable } from '@angular/core';
import { InvestmentApiService } from '@pfa/api';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, share, switchMap, timeout } from 'rxjs/operators';
import {
  AdvisorMenuSections,
  getNewBusinessSections
} from './advisor-menu.content';
import { AdvisorMenu, AdvisorMenuSection } from './advisor-menu.model';
import {
  DigitalSalgType,
  DynamicAdvisingCalculation,
  DynamicAdvisingOffer,
  DynamicAdvisingOfferGroupType,
  DynamicadvisingStore,
  PensionskundeStore,
  SystemadministrationStore
} from '@pfa/gen';
import { DynamicAdvisingFilterService } from '@mitpfa/shared/services/dynamic-advising-filter.service';
import { NewBusinessAdvisoryService } from '@pfa/services';

@Injectable()
export class AdvisorMenuService {
  advisorMenu$: Observable<AdvisorMenu>;
  newBusinessMenu$: Observable<AdvisorMenu>;

  constructor(
    private advisingService: NewBusinessAdvisoryService,
    private readonly dynamicAdvisingFilterService: DynamicAdvisingFilterService,
    private dynamicadvisingStore: DynamicadvisingStore,
    private systemadministrationStore: SystemadministrationStore,
    private investmentApiService: InvestmentApiService,
    private pensionskundeStore: PensionskundeStore
  ) {
    const show360$ = this.systemadministrationStore
      .systemadministrationHentkillswitchesadminGet()
      .pipe(
        switchMap(res => {
          if (res.killswitchDetailsDynamicAdvising.open) {
            return this.investmentApiService
              .get()
              .pipe(
                map(
                  investments =>
                    investments.detaljer.policer.find(
                      policy => policy.id === investments.detaljer.primaerPolice
                    ) !== undefined
                )
              );
          } else {
            return of(false);
          }
        })
      );

    const proLink$ = this.pensionskundeStore
      .pensionskundeGet()
      .pipe(
        map(
          pensionCustomer =>
            '/pro/engagement/oversigt/' + pensionCustomer.globalId
        )
      );

    this.advisorMenu$ = combineLatest({
      adviceSeverity: this.getMaxAdviceSeverity(),
      proLink: proLink$,
      show360: show360$
    }).pipe(
      map(({ show360, proLink, adviceSeverity }) => ({
        adviceSeverity,
        proLink,
        show360,
        sections: AdvisorMenuSections,
        displayHeaderLinks: true,
        open: false
      }))
    );

    this.newBusinessMenu$ = this.getNewBusinessSections().pipe(
      map(sections => ({
        sections,
        displayHeaderLinks: false,
        open: true
      }))
    );
  }

  private getNewBusinessSections(): Observable<AdvisorMenuSection[]> {
    return this.advisingService.newBusinessAdvisory$.pipe(
      map(newBusinessAdvisory => {
        const hideInvestment =
          newBusinessAdvisory.digitalSalgType ===
            DigitalSalgType.VidereFoerelse ||
          (newBusinessAdvisory.digitalSalgType === DigitalSalgType.Nytegning &&
            newBusinessAdvisory.digitalSalgPoliceNrReused);
        return getNewBusinessSections(
          newBusinessAdvisory.digitalSalgPoliceNr,
          hideInvestment
        );
      }),
      share()
    );
  }

  private getMaxAdviceSeverity(): Observable<number> {
    return this.dynamicadvisingStore.dynamicadvisingGet().pipe(
      timeout(4000),
      map((dynamicAdvisingCalculation: DynamicAdvisingCalculation) =>
        Math.max(
          ...this.dynamicAdvisingFilterService
            .filterOfferGroupsByType(
              dynamicAdvisingCalculation.offerGroups,
              DynamicAdvisingOfferGroupType.All
            )
            .offers.map((offer: DynamicAdvisingOffer) => offer.offerSeverity)
        )
      ),
      catchError(() => of(0))
    );
  }
}
