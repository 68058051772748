import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        IndbetalingOversigt,
        IndbetalingOversigtSchema
} from '../model/models';

import { IndbetalingService } from './indbetaling.service';


@Injectable({
    providedIn: 'root'
})
export class IndbetalingStore extends GenericStore {

            private indbetalingIndexCache: Map<string, RestCacheEntry<IndbetalingOversigt>> = new Map<string, RestCacheEntry<IndbetalingOversigt>>();

    constructor(private indbetalingService: IndbetalingService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidateindbetalingIndex();
    }


                public indbetalingGet() : Observable<IndbetalingOversigt> {
                    let hash = "0";
                    if (this.indbetalingIndexCache.has(hash)) {
                        return this.indbetalingIndexCache.get(hash)!.observable;
                    } else {
                        let indbetalingIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let indbetalingIndex = this.initiateStream(indbetalingIndexSubject, this.indbetalingService.indbetalingGet(), IndbetalingOversigtSchema);
                        this.indbetalingIndexCache.set(hash, new RestCacheEntry<IndbetalingOversigt>(indbetalingIndex, indbetalingIndexSubject));
                        return indbetalingIndex;
                    }
                }

                public invalidateindbetalingIndex() {
                    this.indbetalingIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






}