import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        InvestmentChangesPreview,
        InvestmentChangesPreviewSchema
} from '../model/models';

import { MineaendringerService } from './mineaendringer.service';


@Injectable({
    providedIn: 'root'
})
export class MineaendringerStore extends GenericStore {

            private mineaendringerinvestmentchangespreviewIndexCache: Map<string, RestCacheEntry<InvestmentChangesPreview>> = new Map<string, RestCacheEntry<InvestmentChangesPreview>>();

    constructor(private mineaendringerService: MineaendringerService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidatemineaendringerinvestmentchangespreviewIndex();
    }


                public mineaendringerInvestmentchangespreviewGet() : Observable<InvestmentChangesPreview> {
                    let hash = "0";
                    if (this.mineaendringerinvestmentchangespreviewIndexCache.has(hash)) {
                        return this.mineaendringerinvestmentchangespreviewIndexCache.get(hash)!.observable;
                    } else {
                        let mineaendringerinvestmentchangespreviewIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let mineaendringerinvestmentchangespreviewIndex = this.initiateStream(mineaendringerinvestmentchangespreviewIndexSubject, this.mineaendringerService.mineaendringerInvestmentchangespreviewGet(), InvestmentChangesPreviewSchema);
                        this.mineaendringerinvestmentchangespreviewIndexCache.set(hash, new RestCacheEntry<InvestmentChangesPreview>(mineaendringerinvestmentchangespreviewIndex, mineaendringerinvestmentchangespreviewIndexSubject));
                        return mineaendringerinvestmentchangespreviewIndex;
                    }
                }

                public invalidatemineaendringerinvestmentchangespreviewIndex() {
                    this.mineaendringerinvestmentchangespreviewIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






}