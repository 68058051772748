import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        OpenForLanguage,
        OpenForLanguageSchema
} from '../model/models';

import { OpenforbusinessService } from './openforbusiness.service';


@Injectable({
    providedIn: 'root'
})
export class OpenforbusinessStore extends GenericStore {

            private searchopenforbusinessIndexCache: Map<string, RestCacheEntry<OpenForLanguage>> = new Map<string, RestCacheEntry<OpenForLanguage>>();

    constructor(private openforbusinessService: OpenforbusinessService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidatesearchopenforbusinessIndex();
    }


                public searchOpenforbusinessGet() : Observable<OpenForLanguage> {
                    let hash = "0";
                    if (this.searchopenforbusinessIndexCache.has(hash)) {
                        return this.searchopenforbusinessIndexCache.get(hash)!.observable;
                    } else {
                        let searchopenforbusinessIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let searchopenforbusinessIndex = this.initiateStream(searchopenforbusinessIndexSubject, this.openforbusinessService.searchOpenforbusinessGet(), OpenForLanguageSchema);
                        this.searchopenforbusinessIndexCache.set(hash, new RestCacheEntry<OpenForLanguage>(searchopenforbusinessIndex, searchopenforbusinessIndexSubject));
                        return searchopenforbusinessIndex;
                    }
                }

                public invalidatesearchopenforbusinessIndex() {
                    this.searchopenforbusinessIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






}