/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { HierarchicalDiagnosisModel } from '../model/hierarchicalDiagnosisModel';
// @ts-ignore
import { LanguageCode } from '../model/languageCode';
// @ts-ignore
import { SignResponse } from '../model/signResponse';
// @ts-ignore
import { SignedRequest } from '../model/signedRequest';
// @ts-ignore
import { SignedResponse } from '../model/signedResponse';
// @ts-ignore
import { SkadesAnmeldelseConfig } from '../model/skadesAnmeldelseConfig';
// @ts-ignore
import { SpoergeskemaMedInstansId } from '../model/spoergeskemaMedInstansId';
// @ts-ignore
import { Survey } from '../model/survey';
// @ts-ignore
import { TreatmentType } from '../model/treatmentType';
// @ts-ignore
import { UploadedFile } from '../model/uploadedFile';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration, BasePaths }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class SkadesanmeldelseService {

    public basePath = 'http://localhost/ds/api/temp';
    public pathConfigurations?: BasePaths;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (configuration?.basePathOverride) {
            this.pathConfigurations = configuration.basePathOverride('skadesanmeldelse')
        }
        else if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
            this.basePath = basePath;
        } else {
            this.basePath = this.configuration.basePath
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseConfigGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<SkadesAnmeldelseConfig>;
    public skadesanmeldelseConfigGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<SkadesAnmeldelseConfig>>;
    public skadesanmeldelseConfigGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<SkadesAnmeldelseConfig>>;
    public skadesanmeldelseConfigGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/config`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseConfigGet') || this.pathConfigurations.base;
        }
        return this.httpClient.request<SkadesAnmeldelseConfig>('get', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param sprog The language used for the cases
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseHsBehandlingsformerGet(sprog: LanguageCode, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<Array<TreatmentType>>;
    public skadesanmeldelseHsBehandlingsformerGet(sprog: LanguageCode, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<Array<TreatmentType>>>;
    public skadesanmeldelseHsBehandlingsformerGet(sprog: LanguageCode, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<Array<TreatmentType>>>;
    public skadesanmeldelseHsBehandlingsformerGet(sprog: LanguageCode, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (sprog === null || sprog === undefined) {
            throw new Error('Required parameter sprog was null or undefined when calling skadesanmeldelseHsBehandlingsformerGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sprog !== undefined && sprog !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sprog, 'sprog');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/hs/behandlingsformer`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseHsBehandlingsformerGet') || this.pathConfigurations.base;
        }
        return this.httpClient.request<Array<TreatmentType>>('get', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param sprog The language used for the cases
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseHsDiagnoserGet(sprog: LanguageCode, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<Array<HierarchicalDiagnosisModel>>;
    public skadesanmeldelseHsDiagnoserGet(sprog: LanguageCode, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<Array<HierarchicalDiagnosisModel>>>;
    public skadesanmeldelseHsDiagnoserGet(sprog: LanguageCode, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<Array<HierarchicalDiagnosisModel>>>;
    public skadesanmeldelseHsDiagnoserGet(sprog: LanguageCode, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (sprog === null || sprog === undefined) {
            throw new Error('Required parameter sprog was null or undefined when calling skadesanmeldelseHsDiagnoserGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sprog !== undefined && sprog !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sprog, 'sprog');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/hs/diagnoser`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseHsDiagnoserGet') || this.pathConfigurations.base;
        }
        return this.httpClient.request<Array<HierarchicalDiagnosisModel>>('get', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseHsGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<Array<Survey>>;
    public skadesanmeldelseHsGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<Array<Survey>>>;
    public skadesanmeldelseHsGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<Array<Survey>>>;
    public skadesanmeldelseHsGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/hs`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseHsGet') || this.pathConfigurations.base;
        }
        return this.httpClient.request<Array<Survey>>('get', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param instanceUuid The claim uuid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseHsInstanceUuidDocumentsGet(instanceUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<Array<UploadedFile>>;
    public skadesanmeldelseHsInstanceUuidDocumentsGet(instanceUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<Array<UploadedFile>>>;
    public skadesanmeldelseHsInstanceUuidDocumentsGet(instanceUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<Array<UploadedFile>>>;
    public skadesanmeldelseHsInstanceUuidDocumentsGet(instanceUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (instanceUuid === null || instanceUuid === undefined) {
            throw new Error('Required parameter instanceUuid was null or undefined when calling skadesanmeldelseHsInstanceUuidDocumentsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/hs/${this.configuration.encodeParam({name: "instanceUuid", value: instanceUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/documents`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseHsInstanceUuidDocumentsGet') || this.pathConfigurations.base;
        }
        return this.httpClient.request<Array<UploadedFile>>('get', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param sprog The language used for the cases
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseHsPost(sprog: LanguageCode, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<Survey>;
    public skadesanmeldelseHsPost(sprog: LanguageCode, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<Survey>>;
    public skadesanmeldelseHsPost(sprog: LanguageCode, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<Survey>>;
    public skadesanmeldelseHsPost(sprog: LanguageCode, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (sprog === null || sprog === undefined) {
            throw new Error('Required parameter sprog was null or undefined when calling skadesanmeldelseHsPost.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sprog !== undefined && sprog !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sprog, 'sprog');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/hs`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseHsPost') || this.pathConfigurations.base;
        }
        return this.httpClient.request<Survey>('post', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uuid The claim uuid
     * @param docid The document uuid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseHsUuidDokumentDocidDelete(uuid: string, docid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public skadesanmeldelseHsUuidDokumentDocidDelete(uuid: string, docid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public skadesanmeldelseHsUuidDokumentDocidDelete(uuid: string, docid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public skadesanmeldelseHsUuidDokumentDocidDelete(uuid: string, docid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling skadesanmeldelseHsUuidDokumentDocidDelete.');
        }
        if (docid === null || docid === undefined) {
            throw new Error('Required parameter docid was null or undefined when calling skadesanmeldelseHsUuidDokumentDocidDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/hs/${this.configuration.encodeParam({name: "uuid", value: uuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/dokument/${this.configuration.encodeParam({name: "docid", value: docid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseHsUuidDokumentDocidDelete') || this.pathConfigurations.base;
        }
        return this.httpClient.request<any>('delete', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uuid The claim uuid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseHsUuidGet(uuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<SpoergeskemaMedInstansId>;
    public skadesanmeldelseHsUuidGet(uuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<SpoergeskemaMedInstansId>>;
    public skadesanmeldelseHsUuidGet(uuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<SpoergeskemaMedInstansId>>;
    public skadesanmeldelseHsUuidGet(uuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling skadesanmeldelseHsUuidGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/hs/${this.configuration.encodeParam({name: "uuid", value: uuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseHsUuidGet') || this.pathConfigurations.base;
        }
        return this.httpClient.request<SpoergeskemaMedInstansId>('get', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uuid Claim uuid
     * @param sprog The language used for the cases
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseHsUuidPost(uuid: string, sprog: LanguageCode, body?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public skadesanmeldelseHsUuidPost(uuid: string, sprog: LanguageCode, body?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public skadesanmeldelseHsUuidPost(uuid: string, sprog: LanguageCode, body?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public skadesanmeldelseHsUuidPost(uuid: string, sprog: LanguageCode, body?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling skadesanmeldelseHsUuidPost.');
        }
        if (sprog === null || sprog === undefined) {
            throw new Error('Required parameter sprog was null or undefined when calling skadesanmeldelseHsUuidPost.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sprog !== undefined && sprog !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sprog, 'sprog');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/hs/${this.configuration.encodeParam({name: "uuid", value: uuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseHsUuidPost') || this.pathConfigurations.base;
        }
        return this.httpClient.request<any>('post', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uuid Claim uuid
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseHsUuidPut(uuid: string, body?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public skadesanmeldelseHsUuidPut(uuid: string, body?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public skadesanmeldelseHsUuidPut(uuid: string, body?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public skadesanmeldelseHsUuidPut(uuid: string, body?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling skadesanmeldelseHsUuidPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/hs/${this.configuration.encodeParam({name: "uuid", value: uuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseHsUuidPut') || this.pathConfigurations.base;
        }
        return this.httpClient.request<any>('put', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param sprog The language used for the cases
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseKsDiagnoserGet(sprog: LanguageCode, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<Array<HierarchicalDiagnosisModel>>;
    public skadesanmeldelseKsDiagnoserGet(sprog: LanguageCode, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<Array<HierarchicalDiagnosisModel>>>;
    public skadesanmeldelseKsDiagnoserGet(sprog: LanguageCode, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<Array<HierarchicalDiagnosisModel>>>;
    public skadesanmeldelseKsDiagnoserGet(sprog: LanguageCode, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (sprog === null || sprog === undefined) {
            throw new Error('Required parameter sprog was null or undefined when calling skadesanmeldelseKsDiagnoserGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sprog !== undefined && sprog !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sprog, 'sprog');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/ks/diagnoser`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseKsDiagnoserGet') || this.pathConfigurations.base;
        }
        return this.httpClient.request<Array<HierarchicalDiagnosisModel>>('get', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseKsGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<Array<Survey>>;
    public skadesanmeldelseKsGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<Array<Survey>>>;
    public skadesanmeldelseKsGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<Array<Survey>>>;
    public skadesanmeldelseKsGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/ks`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseKsGet') || this.pathConfigurations.base;
        }
        return this.httpClient.request<Array<Survey>>('get', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param instanceUuid The claim uuid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseKsInstanceUuidDocumentsGet(instanceUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<Array<UploadedFile>>;
    public skadesanmeldelseKsInstanceUuidDocumentsGet(instanceUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<Array<UploadedFile>>>;
    public skadesanmeldelseKsInstanceUuidDocumentsGet(instanceUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<Array<UploadedFile>>>;
    public skadesanmeldelseKsInstanceUuidDocumentsGet(instanceUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (instanceUuid === null || instanceUuid === undefined) {
            throw new Error('Required parameter instanceUuid was null or undefined when calling skadesanmeldelseKsInstanceUuidDocumentsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/ks/${this.configuration.encodeParam({name: "instanceUuid", value: instanceUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/documents`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseKsInstanceUuidDocumentsGet') || this.pathConfigurations.base;
        }
        return this.httpClient.request<Array<UploadedFile>>('get', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param sprog The language used for the cases
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseKsPost(sprog: LanguageCode, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<Survey>;
    public skadesanmeldelseKsPost(sprog: LanguageCode, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<Survey>>;
    public skadesanmeldelseKsPost(sprog: LanguageCode, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<Survey>>;
    public skadesanmeldelseKsPost(sprog: LanguageCode, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (sprog === null || sprog === undefined) {
            throw new Error('Required parameter sprog was null or undefined when calling skadesanmeldelseKsPost.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sprog !== undefined && sprog !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sprog, 'sprog');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/ks`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseKsPost') || this.pathConfigurations.base;
        }
        return this.httpClient.request<Survey>('post', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param sprog The language used for the cases
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseKsTidligereDiagnoserGet(sprog: LanguageCode, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<Array<HierarchicalDiagnosisModel>>;
    public skadesanmeldelseKsTidligereDiagnoserGet(sprog: LanguageCode, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<Array<HierarchicalDiagnosisModel>>>;
    public skadesanmeldelseKsTidligereDiagnoserGet(sprog: LanguageCode, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<Array<HierarchicalDiagnosisModel>>>;
    public skadesanmeldelseKsTidligereDiagnoserGet(sprog: LanguageCode, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (sprog === null || sprog === undefined) {
            throw new Error('Required parameter sprog was null or undefined when calling skadesanmeldelseKsTidligereDiagnoserGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sprog !== undefined && sprog !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sprog, 'sprog');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/ks/tidligereDiagnoser`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseKsTidligereDiagnoserGet') || this.pathConfigurations.base;
        }
        return this.httpClient.request<Array<HierarchicalDiagnosisModel>>('get', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uuid The claim uuid
     * @param sprog The language to use
     * @param signedRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseKsUuidConsentSign1Put(uuid: string, sprog: LanguageCode, signedRequest?: SignedRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<SignedResponse>;
    public skadesanmeldelseKsUuidConsentSign1Put(uuid: string, sprog: LanguageCode, signedRequest?: SignedRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<SignedResponse>>;
    public skadesanmeldelseKsUuidConsentSign1Put(uuid: string, sprog: LanguageCode, signedRequest?: SignedRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<SignedResponse>>;
    public skadesanmeldelseKsUuidConsentSign1Put(uuid: string, sprog: LanguageCode, signedRequest?: SignedRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling skadesanmeldelseKsUuidConsentSign1Put.');
        }
        if (sprog === null || sprog === undefined) {
            throw new Error('Required parameter sprog was null or undefined when calling skadesanmeldelseKsUuidConsentSign1Put.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sprog !== undefined && sprog !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sprog, 'sprog');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/ks/${this.configuration.encodeParam({name: "uuid", value: uuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/consent/sign/1`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseKsUuidConsentSign1Put') || this.pathConfigurations.base;
        }
        return this.httpClient.request<SignedResponse>('put', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: signedRequest,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uuid The claim uuid
     * @param sprog The language to use
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseKsUuidConsentSignPost(uuid: string, sprog: LanguageCode, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<SignResponse>;
    public skadesanmeldelseKsUuidConsentSignPost(uuid: string, sprog: LanguageCode, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<SignResponse>>;
    public skadesanmeldelseKsUuidConsentSignPost(uuid: string, sprog: LanguageCode, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<SignResponse>>;
    public skadesanmeldelseKsUuidConsentSignPost(uuid: string, sprog: LanguageCode, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling skadesanmeldelseKsUuidConsentSignPost.');
        }
        if (sprog === null || sprog === undefined) {
            throw new Error('Required parameter sprog was null or undefined when calling skadesanmeldelseKsUuidConsentSignPost.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sprog !== undefined && sprog !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sprog, 'sprog');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/ks/${this.configuration.encodeParam({name: "uuid", value: uuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/consent/sign`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseKsUuidConsentSignPost') || this.pathConfigurations.base;
        }
        return this.httpClient.request<SignResponse>('post', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uuid The claim uuid
     * @param docid The document uuid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseKsUuidDokumentDocidDelete(uuid: string, docid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public skadesanmeldelseKsUuidDokumentDocidDelete(uuid: string, docid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public skadesanmeldelseKsUuidDokumentDocidDelete(uuid: string, docid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public skadesanmeldelseKsUuidDokumentDocidDelete(uuid: string, docid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling skadesanmeldelseKsUuidDokumentDocidDelete.');
        }
        if (docid === null || docid === undefined) {
            throw new Error('Required parameter docid was null or undefined when calling skadesanmeldelseKsUuidDokumentDocidDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/ks/${this.configuration.encodeParam({name: "uuid", value: uuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/dokument/${this.configuration.encodeParam({name: "docid", value: docid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseKsUuidDokumentDocidDelete') || this.pathConfigurations.base;
        }
        return this.httpClient.request<any>('delete', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uuid The claim uuid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseKsUuidGet(uuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<SpoergeskemaMedInstansId>;
    public skadesanmeldelseKsUuidGet(uuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<SpoergeskemaMedInstansId>>;
    public skadesanmeldelseKsUuidGet(uuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<SpoergeskemaMedInstansId>>;
    public skadesanmeldelseKsUuidGet(uuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling skadesanmeldelseKsUuidGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/ks/${this.configuration.encodeParam({name: "uuid", value: uuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseKsUuidGet') || this.pathConfigurations.base;
        }
        return this.httpClient.request<SpoergeskemaMedInstansId>('get', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uuid Claim uuid
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseKsUuidPut(uuid: string, body?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public skadesanmeldelseKsUuidPut(uuid: string, body?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public skadesanmeldelseKsUuidPut(uuid: string, body?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public skadesanmeldelseKsUuidPut(uuid: string, body?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling skadesanmeldelseKsUuidPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/ks/${this.configuration.encodeParam({name: "uuid", value: uuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseKsUuidPut') || this.pathConfigurations.base;
        }
        return this.httpClient.request<any>('put', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uuid The claim uuid
     * @param sprog The language to use
     * @param signedRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseKsUuidSign1Put(uuid: string, sprog: LanguageCode, signedRequest?: SignedRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<SignedResponse>;
    public skadesanmeldelseKsUuidSign1Put(uuid: string, sprog: LanguageCode, signedRequest?: SignedRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<SignedResponse>>;
    public skadesanmeldelseKsUuidSign1Put(uuid: string, sprog: LanguageCode, signedRequest?: SignedRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<SignedResponse>>;
    public skadesanmeldelseKsUuidSign1Put(uuid: string, sprog: LanguageCode, signedRequest?: SignedRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling skadesanmeldelseKsUuidSign1Put.');
        }
        if (sprog === null || sprog === undefined) {
            throw new Error('Required parameter sprog was null or undefined when calling skadesanmeldelseKsUuidSign1Put.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sprog !== undefined && sprog !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sprog, 'sprog');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/ks/${this.configuration.encodeParam({name: "uuid", value: uuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/sign/1`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseKsUuidSign1Put') || this.pathConfigurations.base;
        }
        return this.httpClient.request<SignedResponse>('put', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: signedRequest,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uuid The claim uuid
     * @param sprog The language to use
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseKsUuidSignPost(uuid: string, sprog: LanguageCode, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<SignResponse>;
    public skadesanmeldelseKsUuidSignPost(uuid: string, sprog: LanguageCode, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<SignResponse>>;
    public skadesanmeldelseKsUuidSignPost(uuid: string, sprog: LanguageCode, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<SignResponse>>;
    public skadesanmeldelseKsUuidSignPost(uuid: string, sprog: LanguageCode, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling skadesanmeldelseKsUuidSignPost.');
        }
        if (sprog === null || sprog === undefined) {
            throw new Error('Required parameter sprog was null or undefined when calling skadesanmeldelseKsUuidSignPost.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sprog !== undefined && sprog !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sprog, 'sprog');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/ks/${this.configuration.encodeParam({name: "uuid", value: uuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/sign`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseKsUuidSignPost') || this.pathConfigurations.base;
        }
        return this.httpClient.request<SignResponse>('post', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param sprog The language used for the cases
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseTaeDiagnoserGet(sprog: LanguageCode, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<Array<HierarchicalDiagnosisModel>>;
    public skadesanmeldelseTaeDiagnoserGet(sprog: LanguageCode, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<Array<HierarchicalDiagnosisModel>>>;
    public skadesanmeldelseTaeDiagnoserGet(sprog: LanguageCode, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<Array<HierarchicalDiagnosisModel>>>;
    public skadesanmeldelseTaeDiagnoserGet(sprog: LanguageCode, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (sprog === null || sprog === undefined) {
            throw new Error('Required parameter sprog was null or undefined when calling skadesanmeldelseTaeDiagnoserGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sprog !== undefined && sprog !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sprog, 'sprog');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/tae/diagnoser`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseTaeDiagnoserGet') || this.pathConfigurations.base;
        }
        return this.httpClient.request<Array<HierarchicalDiagnosisModel>>('get', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseTaeGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<Array<Survey>>;
    public skadesanmeldelseTaeGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<Array<Survey>>>;
    public skadesanmeldelseTaeGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<Array<Survey>>>;
    public skadesanmeldelseTaeGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/tae`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseTaeGet') || this.pathConfigurations.base;
        }
        return this.httpClient.request<Array<Survey>>('get', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param instanceUuid The claim uuid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseTaeInstanceUuidDocumentsGet(instanceUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<Array<UploadedFile>>;
    public skadesanmeldelseTaeInstanceUuidDocumentsGet(instanceUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<Array<UploadedFile>>>;
    public skadesanmeldelseTaeInstanceUuidDocumentsGet(instanceUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<Array<UploadedFile>>>;
    public skadesanmeldelseTaeInstanceUuidDocumentsGet(instanceUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (instanceUuid === null || instanceUuid === undefined) {
            throw new Error('Required parameter instanceUuid was null or undefined when calling skadesanmeldelseTaeInstanceUuidDocumentsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/tae/${this.configuration.encodeParam({name: "instanceUuid", value: instanceUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/documents`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseTaeInstanceUuidDocumentsGet') || this.pathConfigurations.base;
        }
        return this.httpClient.request<Array<UploadedFile>>('get', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param sprog The language used for the cases
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseTaeJobtyperGet(sprog: LanguageCode, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<Array<HierarchicalDiagnosisModel>>;
    public skadesanmeldelseTaeJobtyperGet(sprog: LanguageCode, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<Array<HierarchicalDiagnosisModel>>>;
    public skadesanmeldelseTaeJobtyperGet(sprog: LanguageCode, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<Array<HierarchicalDiagnosisModel>>>;
    public skadesanmeldelseTaeJobtyperGet(sprog: LanguageCode, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (sprog === null || sprog === undefined) {
            throw new Error('Required parameter sprog was null or undefined when calling skadesanmeldelseTaeJobtyperGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sprog !== undefined && sprog !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sprog, 'sprog');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/tae/jobtyper`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseTaeJobtyperGet') || this.pathConfigurations.base;
        }
        return this.httpClient.request<Array<HierarchicalDiagnosisModel>>('get', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param sprog The language used for the cases
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseTaePost(sprog: LanguageCode, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<Survey>;
    public skadesanmeldelseTaePost(sprog: LanguageCode, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<Survey>>;
    public skadesanmeldelseTaePost(sprog: LanguageCode, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<Survey>>;
    public skadesanmeldelseTaePost(sprog: LanguageCode, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (sprog === null || sprog === undefined) {
            throw new Error('Required parameter sprog was null or undefined when calling skadesanmeldelseTaePost.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sprog !== undefined && sprog !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sprog, 'sprog');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/tae`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseTaePost') || this.pathConfigurations.base;
        }
        return this.httpClient.request<Survey>('post', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uuid The claim uuid
     * @param sprog The language to use
     * @param signedRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseTaeUuidConsentSign1Put(uuid: string, sprog: LanguageCode, signedRequest?: SignedRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<SignedResponse>;
    public skadesanmeldelseTaeUuidConsentSign1Put(uuid: string, sprog: LanguageCode, signedRequest?: SignedRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<SignedResponse>>;
    public skadesanmeldelseTaeUuidConsentSign1Put(uuid: string, sprog: LanguageCode, signedRequest?: SignedRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<SignedResponse>>;
    public skadesanmeldelseTaeUuidConsentSign1Put(uuid: string, sprog: LanguageCode, signedRequest?: SignedRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling skadesanmeldelseTaeUuidConsentSign1Put.');
        }
        if (sprog === null || sprog === undefined) {
            throw new Error('Required parameter sprog was null or undefined when calling skadesanmeldelseTaeUuidConsentSign1Put.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sprog !== undefined && sprog !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sprog, 'sprog');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/tae/${this.configuration.encodeParam({name: "uuid", value: uuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/consent/sign/1`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseTaeUuidConsentSign1Put') || this.pathConfigurations.base;
        }
        return this.httpClient.request<SignedResponse>('put', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: signedRequest,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uuid The claim uuid
     * @param sprog The language to use
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseTaeUuidConsentSignPost(uuid: string, sprog: LanguageCode, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<SignResponse>;
    public skadesanmeldelseTaeUuidConsentSignPost(uuid: string, sprog: LanguageCode, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<SignResponse>>;
    public skadesanmeldelseTaeUuidConsentSignPost(uuid: string, sprog: LanguageCode, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<SignResponse>>;
    public skadesanmeldelseTaeUuidConsentSignPost(uuid: string, sprog: LanguageCode, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling skadesanmeldelseTaeUuidConsentSignPost.');
        }
        if (sprog === null || sprog === undefined) {
            throw new Error('Required parameter sprog was null or undefined when calling skadesanmeldelseTaeUuidConsentSignPost.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sprog !== undefined && sprog !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sprog, 'sprog');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/tae/${this.configuration.encodeParam({name: "uuid", value: uuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/consent/sign`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseTaeUuidConsentSignPost') || this.pathConfigurations.base;
        }
        return this.httpClient.request<SignResponse>('post', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uuid The claim uuid
     * @param docid The document uuid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseTaeUuidDokumentDocidDelete(uuid: string, docid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public skadesanmeldelseTaeUuidDokumentDocidDelete(uuid: string, docid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public skadesanmeldelseTaeUuidDokumentDocidDelete(uuid: string, docid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public skadesanmeldelseTaeUuidDokumentDocidDelete(uuid: string, docid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling skadesanmeldelseTaeUuidDokumentDocidDelete.');
        }
        if (docid === null || docid === undefined) {
            throw new Error('Required parameter docid was null or undefined when calling skadesanmeldelseTaeUuidDokumentDocidDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/tae/${this.configuration.encodeParam({name: "uuid", value: uuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/dokument/${this.configuration.encodeParam({name: "docid", value: docid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseTaeUuidDokumentDocidDelete') || this.pathConfigurations.base;
        }
        return this.httpClient.request<any>('delete', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uuid The claim uuid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseTaeUuidGet(uuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<SpoergeskemaMedInstansId>;
    public skadesanmeldelseTaeUuidGet(uuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<SpoergeskemaMedInstansId>>;
    public skadesanmeldelseTaeUuidGet(uuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<SpoergeskemaMedInstansId>>;
    public skadesanmeldelseTaeUuidGet(uuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling skadesanmeldelseTaeUuidGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/tae/${this.configuration.encodeParam({name: "uuid", value: uuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseTaeUuidGet') || this.pathConfigurations.base;
        }
        return this.httpClient.request<SpoergeskemaMedInstansId>('get', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uuid Claim uuid
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseTaeUuidPut(uuid: string, body?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public skadesanmeldelseTaeUuidPut(uuid: string, body?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public skadesanmeldelseTaeUuidPut(uuid: string, body?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public skadesanmeldelseTaeUuidPut(uuid: string, body?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling skadesanmeldelseTaeUuidPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/tae/${this.configuration.encodeParam({name: "uuid", value: uuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseTaeUuidPut') || this.pathConfigurations.base;
        }
        return this.httpClient.request<any>('put', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uuid The claim uuid
     * @param sprog The language to use
     * @param signedRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseTaeUuidSign1Put(uuid: string, sprog: LanguageCode, signedRequest?: SignedRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<SignedResponse>;
    public skadesanmeldelseTaeUuidSign1Put(uuid: string, sprog: LanguageCode, signedRequest?: SignedRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<SignedResponse>>;
    public skadesanmeldelseTaeUuidSign1Put(uuid: string, sprog: LanguageCode, signedRequest?: SignedRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<SignedResponse>>;
    public skadesanmeldelseTaeUuidSign1Put(uuid: string, sprog: LanguageCode, signedRequest?: SignedRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling skadesanmeldelseTaeUuidSign1Put.');
        }
        if (sprog === null || sprog === undefined) {
            throw new Error('Required parameter sprog was null or undefined when calling skadesanmeldelseTaeUuidSign1Put.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sprog !== undefined && sprog !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sprog, 'sprog');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/tae/${this.configuration.encodeParam({name: "uuid", value: uuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/sign/1`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseTaeUuidSign1Put') || this.pathConfigurations.base;
        }
        return this.httpClient.request<SignedResponse>('put', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: signedRequest,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uuid The claim uuid
     * @param sprog The language to use
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public skadesanmeldelseTaeUuidSignPost(uuid: string, sprog: LanguageCode, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<SignResponse>;
    public skadesanmeldelseTaeUuidSignPost(uuid: string, sprog: LanguageCode, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpResponse<SignResponse>>;
    public skadesanmeldelseTaeUuidSignPost(uuid: string, sprog: LanguageCode, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<HttpEvent<SignResponse>>;
    public skadesanmeldelseTaeUuidSignPost(uuid: string, sprog: LanguageCode, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;UTF-8', context?: HttpContext}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling skadesanmeldelseTaeUuidSignPost.');
        }
        if (sprog === null || sprog === undefined) {
            throw new Error('Required parameter sprog was null or undefined when calling skadesanmeldelseTaeUuidSignPost.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sprog !== undefined && sprog !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sprog, 'sprog');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skadesanmeldelse/tae/${this.configuration.encodeParam({name: "uuid", value: uuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/sign`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('skadesanmeldelseTaeUuidSignPost') || this.pathConfigurations.base;
        }
        return this.httpClient.request<SignResponse>('post', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
