import { SignatureIframeResponse } from '@pfa/gen';

export enum InjuryClaimSigningType {
  SIGNATURE,
  CONSENT
}

export const defaultSignatureIframeResponse: SignatureIframeResponse = {
  fejl: false,
  fejlTekst: '',
  fejlkode: '',
  iframesrc: '',
  revisionKey: '',
  script: '',
  serviceInfo: undefined
};
