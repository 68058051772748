/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VisningsordenType } from './visningsordenType';
import { VisningsordenTypeSchema } from './visningsordenType';
import { FormOfPayment } from './formOfPayment';
import { FormOfPaymentSchema } from './formOfPayment';
import { Forsikrede } from './forsikrede';
import { ForsikredeSchema } from './forsikrede';
import { DaekningsType } from './daekningsType';
import { DaekningsTypeSchema } from './daekningsType';
import * as JoiObj from "joi";


    export const OversigtForsikringSchema = JoiObj.object({
        aegtefaelleCpr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        aftaleNummer:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        antalUdbetalingsAar:
            
                    JoiObj.number()
                

.allow(null)



        ,
        bankpensionUnderudbetaling:
            
                    JoiObj.boolean()
                


.required()


        ,
        beloeb:
            
                    JoiObj.number()
                

.allow(null)



        ,
        betingelseParameter:
            
                    JoiObj.number()
                

.allow(null)



        ,
        boerneUdloebAlder:
            
                    JoiObj.number()
                

.allow(null)



        ,
        daekningsId:
            
                    JoiObj.number()
                

.allow(null)



        ,
        daekningsType:
            
        JoiObj.link('#DaekningsTypeSchema')

.allow(null)



        ,
        ekstraTilkendelseTAE:
            
                    JoiObj.boolean()
                


.required()


        ,
        formOfPayment:
            
        JoiObj.link('#FormOfPaymentSchema')

.allow(null)



        ,
        forsikrede:
            
        JoiObj.link('#ForsikredeSchema')

.allow(null)



        ,
        forsikringSlutDato:
            
            JoiObj.date()

.allow(null)



        ,
        forsikringsContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        fromPI:
            
                    JoiObj.boolean()
                


.required()


        ,
        gennemsnitsrentePolicenr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        gipp:
            
                    JoiObj.boolean()
                


.required()


        ,
        har620Eller820:
            
                    JoiObj.boolean()
                


.required()


        ,
        ikkeVistDaekning:
            
                    JoiObj.boolean()
                


.required()


        ,
        laengestTilUdbetalingAlder:
            
                    JoiObj.number()
                

.allow(null)



        ,
        opgjortPrDato:
            
            JoiObj.date()

.allow(null)



        ,
        risikoOphoerAlder:
            
                    JoiObj.number()
                

.allow(null)



        ,
        samletAegtefaelleBeloeb:
            
                    JoiObj.number()
                

.allow(null)



        ,
        selskab:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        udbetalingBeskatning51:
            
                    JoiObj.boolean()
                


.required()


        ,
        udbetalingSlutAlder:
            
                    JoiObj.number()
                

.allow(null)



        ,
        udbetalingSlutDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        udbetalingStartAlder:
            
                    JoiObj.number()
                

.allow(null)



        ,
        udbetalingsPeriodeTilAegtefaelle:
            
                    JoiObj.number()
                

.allow(null)



        ,
        variantId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        virkeMaade:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        visningsorden:
            
        JoiObj.link('#VisningsordenTypeSchema')


.required()


        
    })
                .shared(DaekningsTypeSchema)
                .shared(FormOfPaymentSchema)
                .shared(ForsikredeSchema)
                .shared(VisningsordenTypeSchema)
.id('OversigtForsikringSchema')

export interface OversigtForsikring { 
    aegtefaelleCpr?: string;
    aftaleNummer?: string;
    antalUdbetalingsAar?: number;
    bankpensionUnderudbetaling: boolean;
    beloeb?: number;
    betingelseParameter?: number;
    boerneUdloebAlder?: number;
    daekningsId?: number;
    daekningsType?: DaekningsType;
    ekstraTilkendelseTAE: boolean;
    formOfPayment?: FormOfPayment;
    forsikrede?: Forsikrede;
    forsikringSlutDato?: Date;
    forsikringsContentId?: string;
    fromPI: boolean;
    gennemsnitsrentePolicenr?: string;
    gipp: boolean;
    har620Eller820: boolean;
    ikkeVistDaekning: boolean;
    laengestTilUdbetalingAlder?: number;
    opgjortPrDato?: Date;
    risikoOphoerAlder?: number;
    samletAegtefaelleBeloeb?: number;
    selskab: string;
    udbetalingBeskatning51: boolean;
    udbetalingSlutAlder?: number;
    udbetalingSlutDato?: string;
    udbetalingStartAlder?: number;
    udbetalingsPeriodeTilAegtefaelle?: number;
    variantId?: string;
    virkeMaade?: string;
    visningsorden: VisningsordenType;
}
export namespace OversigtForsikring {
}


