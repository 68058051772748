import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        Case,
        CaseSchema,
        SprogType,
        SprogTypeSchema
} from '../model/models';

import { IndividualcustomerdossierService } from './individualcustomerdossier.service';


@Injectable({
    providedIn: 'root'
})
export class IndividualcustomerdossierStore extends GenericStore {

            private individualcustomerdossiercasesIndexCache: Map<string, RestCacheEntry<Array<Case>>> = new Map<string, RestCacheEntry<Array<Case>>>();

    constructor(private individualcustomerdossierService: IndividualcustomerdossierService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidateindividualcustomerdossiercasesIndex();
    }


                public individualcustomerdossierCasesGet(language: SprogType) : Observable<Array<Case>> {
                    let hash = this.hashKey((language.toString() || "0"));
                    if (this.individualcustomerdossiercasesIndexCache.has(hash)) {
                        return this.individualcustomerdossiercasesIndexCache.get(hash)!.observable;
                    } else {
                        let individualcustomerdossiercasesIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let individualcustomerdossiercasesIndex = this.initiateStream(individualcustomerdossiercasesIndexSubject, this.individualcustomerdossierService.individualcustomerdossierCasesGet(language), CaseSchema);
                        this.individualcustomerdossiercasesIndexCache.set(hash, new RestCacheEntry<Array<Case>>(individualcustomerdossiercasesIndex, individualcustomerdossiercasesIndexSubject));
                        return individualcustomerdossiercasesIndex;
                    }
                }

                public invalidateindividualcustomerdossiercasesIndex() {
                    this.individualcustomerdossiercasesIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






}