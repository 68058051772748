import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";
import {
        ConsentInfo,
        ConsentInfoSchema,
        MaeglerInfo,
        MaeglerInfoSchema,
        Mobil,
        MobilSchema,
        PensionsKundeGenerelleData,
        PensionsKundeGenerelleDataSchema,
        ProcesseringsStatus,
        ProcesseringsStatusSchema
} from '../model/models';

import { PensionskundeService } from './pensionskunde.service';


@Injectable({
    providedIn: 'root'
})
export class PensionskundeStore extends GenericStore {

            private pensionskundeconsentsIndexCache: Map<string, RestCacheEntry<Array<ConsentInfo>>> = new Map<string, RestCacheEntry<Array<ConsentInfo>>>();
            private pensionskundemaeglerinfoIndexCache: Map<string, RestCacheEntry<MaeglerInfo>> = new Map<string, RestCacheEntry<MaeglerInfo>>();
            private pensionskundeIndexCache: Map<string, RestCacheEntry<PensionsKundeGenerelleData>> = new Map<string, RestCacheEntry<PensionsKundeGenerelleData>>();

    constructor(private pensionskundeService: PensionskundeService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidatepensionskundeconsentsIndex();
                
                this.invalidatepensionskundeIndex();
                
                this.invalidatepensionskundemaeglerinfoIndex();
                
                
    }


                public pensionskundeConsentsGet() : Observable<Array<ConsentInfo>> {
                    let hash = "0";
                    if (this.pensionskundeconsentsIndexCache.has(hash)) {
                        return this.pensionskundeconsentsIndexCache.get(hash)!.observable;
                    } else {
                        let pensionskundeconsentsIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let pensionskundeconsentsIndex = this.initiateStream(pensionskundeconsentsIndexSubject, this.pensionskundeService.pensionskundeConsentsGet(), ConsentInfoSchema);
                        this.pensionskundeconsentsIndexCache.set(hash, new RestCacheEntry<Array<ConsentInfo>>(pensionskundeconsentsIndex, pensionskundeconsentsIndexSubject));
                        return pensionskundeconsentsIndex;
                    }
                }

                public invalidatepensionskundeconsentsIndex() {
                    this.pensionskundeconsentsIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






                public pensionskundeGet(refresh?: boolean) : Observable<PensionsKundeGenerelleData> {
                    let hash = this.hashKey((refresh?.toString() || "0"));
                    if (this.pensionskundeIndexCache.has(hash)) {
                        return this.pensionskundeIndexCache.get(hash)!.observable;
                    } else {
                        let pensionskundeIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let pensionskundeIndex = this.initiateStream(pensionskundeIndexSubject, this.pensionskundeService.pensionskundeGet(refresh), PensionsKundeGenerelleDataSchema);
                        this.pensionskundeIndexCache.set(hash, new RestCacheEntry<PensionsKundeGenerelleData>(pensionskundeIndex, pensionskundeIndexSubject));
                        return pensionskundeIndex;
                    }
                }

                public invalidatepensionskundeIndex() {
                    this.pensionskundeIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






                public pensionskundeMaeglerinfoGet() : Observable<MaeglerInfo> {
                    let hash = "0";
                    if (this.pensionskundemaeglerinfoIndexCache.has(hash)) {
                        return this.pensionskundemaeglerinfoIndexCache.get(hash)!.observable;
                    } else {
                        let pensionskundemaeglerinfoIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let pensionskundemaeglerinfoIndex = this.initiateStream(pensionskundemaeglerinfoIndexSubject, this.pensionskundeService.pensionskundeMaeglerinfoGet(), MaeglerInfoSchema);
                        this.pensionskundemaeglerinfoIndexCache.set(hash, new RestCacheEntry<MaeglerInfo>(pensionskundemaeglerinfoIndex, pensionskundemaeglerinfoIndexSubject));
                        return pensionskundemaeglerinfoIndex;
                    }
                }

                public invalidatepensionskundemaeglerinfoIndex() {
                    this.pensionskundemaeglerinfoIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }









                public pensionskundeOpdaterMobilnrPut(mobil?: Mobil) : Observable<ProcesseringsStatus> {
                    return this.pensionskundeService.pensionskundeOpdaterMobilnrPut(mobil).pipe(
                        map(value => {
                            
                            
                                    this.throwOrHandleValidationError(ProcesseringsStatusSchema, value);
                            return value;
                        })
                    );
                }



}